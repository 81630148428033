import { CIMap } from 'utils/js';

// CoinGecko icons
import cg_mainnet_looksrare from './images/0xf4d2888d29d722226fafa5d9b24f9164c092421e.webp';
import cg_mainnet_xai from './images/0xd7c9f0e536dc865ae858b0c0453fe76d13c3beac.svg';
import cg_mainnet_weth from './images/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.svg';
import cg_mainnet_maker from './images/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.svg';
import cg_mainnet_blur from './images/0x5283d291dbcf85356a21ba090e6db59121208b44.png';
import cg_mainnet_wrapped_nxm from './images/0x0d438f3b5175bebc262bf23753c1e53d03432bde.webp';
import cg_mainnet_perpetual_protocol from './images/0xbc396689893d065f41bc2c6ecbee5e0085233447.webp';
import cg_mainnet_ftx_token from './images/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9.png';
import cg_mainnet_matic_network from './images/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.svg';
import cg_mainnet_badger_dao from './images/0x3472a5a71965499acd81997a54bba8d852c6e53d.png';
import cg_mainnet_funfair from './images/0x419d0d8bdd9af5e606ae2232ed285aff190e711b.webp';
import cg_mainnet_coinbase_wrapped_staked_eth from './images/0xbe9895146f7af43049ca1c1ae358b0541ea49704.svg';
import cg_mainnet_keep3rv1 from './images/0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44.png';
import cg_mainnet_wrapped_bitcoin from './images/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.svg';
import cg_mainnet_frax_ether from './images/0x5e8422345238f34275888049021821e8e08caa1f.svg';
import cg_mainnet_rai from './images/0x03ab458634910aad20ef5f1c8ee96f1d6ac54919.webp';
import cg_mainnet_aave from './images/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.svg';
import cg_arbitrum_one_aave from './images/0xba5ddd1f9d7f570dc94a51479a000e3bce967196.svg';
import cg_mainnet_uniswap from './images/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.svg';
import cg_mainnet_havven from './images/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f.svg';
import cg_mainnet_the_sandbox from './images/0x3845badade8e6dff049820680d1f14bd3903a5d0.png';
import cg_mainnet_wrapped_steth from './images/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0.png';
import cg_mainnet_dydx from './images/0x92d6c1e31e14520e676a687f0a93788b716beff5.svg';
import cg_mainnet_alchemix from './images/0xdbdb4d16eda451d0503b854cf79d55697f90c8df.webp';
import cg_mainnet_ocean_protocol from './images/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png';
import cg_mainnet_liquity from './images/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d.png';
import cg_mainnet_rocket_pool from './images/0xd33526068d116ce69f19a9ee46f0bd304f21a51f.webp';
import cg_mainnet_harvest_finance from './images/0xa0246c9032bc3a600820415ae600c6388619a14d.png';
import cg_mainnet_aura_finance from './images/0xc0c293ce456ff0ed870add98a0828dd4d2903dbf.webp';
import cg_mainnet_frax from './images/0x853d955acef822db058eb8505911ed77f175b99e.svg';
import cg_mainnet_xdefi from './images/0x72b886d09c117654ab7da13a14d603001de0b777.webp';
import cg_mainnet_spell_token from './images/0x090185f2135308bad17527004364ebcc2d37e5f6.webp';
import cg_mainnet_xsushi from './images/0x8798249c2e607446efb7ad49ec89dd1865ff4272.webp';
import cg_mainnet_bitdao from './images/0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5.svg';
import cg_mainnet_dai from './images/0x6b175474e89094c44da98b954eedeac495271d0f.svg';
import cg_mainnet_nftx from './images/0x87d73e916d7057945c9bcd8cdd94e42a6f47f776.webp';
import cg_mainnet_seth2 from './images/0xfe2e637202056d30016725477c5da089ab0a043a.png';
import cg_mainnet_usdd from './images/0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6.svg';
import cg_mainnet_lido_dao from './images/0x5a98fcbea516cf06857215779fd812ca3bef1b32.webp';
import cg_mainnet_bancor from './images/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c.png';
import cg_mainnet_jpeg_d from './images/0xe80c0cd204d654cebe8dd64a4857cab6be8345a3.png';
import cg_mainnet_ageur from './images/0x1a7e4e63778b4f12a199c062f3efdd288afcbce8.webp';
import cg_mainnet_silo_finance from './images/0x6f80310ca7f2c654691d1383149fa1a57d8ab1f8.png';
import cg_mainnet_nusd from './images/0x57ab1ec28d129707052df4df418d58a2d46d5f51.webp';
import cg_mainnet_uma from './images/0x04fa0d235c4abf4bcf4787af4cf447de572ef828.webp';
import cg_mainnet_balancer from './images/0xba100000625a3754423978a60c9317c58a424e3d.svg';
import cg_mainnet_basic_attention_token from './images/0x0d8775f648430679a709e98d2b0cb6250d2887ef.svg';
import cg_mainnet_yearn_finance from './images/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.svg';
import cg_mainnet_convex_finance from './images/0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b.svg';
import cg_mainnet_true_usd from './images/0x0000000000085d4780b73119b644ae5ecd22b376.svg';
import cg_mainnet_curve_dao_token from './images/0xd533a949740bb3306d119cc777fa900ba034cd52.webp';
import cg_mainnet_quant_network from './images/0x4a220e6096b25eadb88358cb44068a3248254675.png';
import cg_mainnet_1inch from './images/0x111111111117dc0aa78b770fa6a738034120c302.svg';
import cg_mainnet_rocket_pool_eth from './images/0xae78736cd615f374d3085123a210448e74fc6393.webp';
import cg_mainnet_binance_usd from './images/0x4fabb145d64652a948d72533023f6e7a623c7c53.svg';
import cg_mainnet_immutable_x from './images/0xf57e7e7c23978c3caec3c3548e3d615c346e79ff.webp';
import cg_mainnet_ethereum_name_service from './images/0xc18360217d8f7ab5e7c516566761ea12ce7f9d72.webp';
import cg_mainnet_liquity_usd from './images/0x5f98805a4e8be255a32880fdec7f6728c6568ba0.svg';
import cg_mainnet_gnosis from './images/0x6810e776880c02933d47db1b9fc05908e5386b96.png';
import cg_mainnet_governance_ohm from './images/0x0ab87046fbb341d058f17cbc4c1133f25a20a52f.webp';
import cg_mainnet_decentraland from './images/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.svg';
import cg_mainnet_chainlink from './images/0x514910771af9ca656af840dff83e8264ecf986ca.svg';
import cg_mainnet_tether from './images/0xdac17f958d2ee523a2206206994597c13d831ec7.svg';
import cg_mainnet_compound_governance_token from './images/0xc00e94cb662c3520282e6f5717214004a7f26888.svg';
import cg_mainnet_crypto_com_chain from './images/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b.svg';
import cg_mainnet_apecoin from './images/0x4d224452801aced8b2f0aebe155379bb5d594381.svg';
import cg_mainnet_paxos_standard from './images/0x8e870d67f660d95d5be530380d0ec0bd388289e1.svg';
import cg_mainnet_gemini_dollar from './images/0x056fd409e1d7a124bd7017459dfea2f387b6d5cd.svg';
import cg_mainnet_shiba_inu from './images/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce.webp';
import cg_mainnet_0x from './images/0xe41d2489571d322189246dafa5ebde1f4699f498.svg';
import cg_mainnet_woo_network from './images/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.svg';
import cg_mainnet_frax_share from './images/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0.webp';
import cg_mainnet_olympus from './images/0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5.png';
import cg_mainnet_magic_internet_money from './images/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3.svg';
import cg_mainnet_sushi from './images/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.svg';
import cg_mainnet_pax_gold from './images/0x45804880de22913dafe09f4980848ece6ecbaf78.svg';
import cg_mainnet_usd_coin from './images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.svg';
import cg_mainnet_enjincoin from './images/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c.webp';
import cg_arbitrum_one_weth from './images/0x82af49447d8a07e3bd95bd0d56f35241523fbab1.svg';
import cg_arbitrum_one_uniswap from './images/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0.svg';
import cg_arbitrum_one_usd_coin from './images/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8.svg';
import cg_arbitrum_one_arbitrum from './images/0x912ce59144191c1204e64559fe8253a0e49e6548.webp';
import cg_arbitrum_one_cap from './images/0x031d35296154279dc1984dcd93e392b1f946737b.png';
import cg_arbitrum_one_sperax from './images/0x5575552988a3a80504bbaeb1311674fcfd40ad4b.webp';
import cg_arbitrum_one_tether from './images/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9.svg';
import cg_arbitrum_one_stargate_finance from './images/0x6694340fc020c5e6b96567843da2df01b2ce1eb6.svg';
import cg_arbitrum_one_magic from './images/0x539bde0d7dbd336b79148aa742883198bbf60342.webp';
import cg_arbitrum_one_premia from './images/0x51fc0f6660482ea73330e414efd7808811a57fa2.png';
import cg_arbitrum_one_wrapped_bitcoin from './images/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f.svg';
import cg_arbitrum_one_dopex from './images/0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55.webp';
import cg_arbitrum_one_jones_dao from './images/0x10393c20975cf177a3513071bc110f7962cd67da.svg';
import cg_arbitrum_one_chainlink from './images/0xf97f4df75117a78c1a5a0dbb814af92458539fb4.svg';
import cg_arbitrum_one_dopex_rebate_token from './images/0x32eb7902d4134bf98a28b963d26de779af92a212.webp';
import cg_arbitrum_one_y2k from './images/0x65c936f008bc34fe819bce9fa5afd9dc2d49977f.webp';
import cg_arbitrum_one_plutusdao from './images/0x51318b7d00db7acc4026c88c3952b66278b6a67f.png';
import cg_arbitrum_one_vela_token from './images/0x088cd8f5ef3652623c22d48b1605dcfe860cd704.webp';
import cg_arbitrum_one_camelot_token from './images/0x3d9907f9a368ad0a51be60f7da3b97cf940982d8.png';
import cg_arbitrum_one_umami_finance from './images/0x1622bf67e6e5747b81866fe0b85178a93c7f86e3.svg';
import cg_arbitrum_one_curve_dao_token from './images/0x11cdb42b0eb46d95f990bedd4695a6e3fa034978.webp';
import cg_arbitrum_one_gmx from './images/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a.png';
import cg_arbitrum_one_dai from './images/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1.svg';
import cg_arbitrum_one_sperax_usd from './images/0xd74f5255d557944cf7dd0e45ff521520002d5748.webp';
import cg_arbitrum_one_gmd_protocol from './images/0x4945970efeec98d393b4b979b9be265a3ae28a8b.webp';
import cg_arbitrum_one_gains_network from './images/0x18c11fd286c5ec11c3b683caa813b77f5163a122.svg';
import cg_mainnet_republic_protocol from './images/0x408e41876cccdc0f92210600ef50372656052a38.svg';
import cg_mainnet_kyber_network from './images/0xdd974d5c2e2928dea5f71b9825b8b646686bd200.png';
import cg_mainnet_ampleforth from './images/0xd46ba6d942050d489dbd938a2c909a5d5039a161.png';
import cg_mainnet_defipulse_index from './images/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.svg';
import cg_mainnet_fei_usd from './images/0x956f47f50a910163d8bf957cf5846d573e7f87ca.png';
import cg_mainnet_staked_ether from './images/0xae7ab96520de3a18e5e111b5eaab095312d7fe84.webp';
import cg_mainnet_terrausd_wormhole from './images/0xa693b19d2931d498c5b318df961919bb4aee87a5.webp';
import cg_mainnet_aave_dai from './images/0x028171bca77440897b824ca71d1c56cac55b68a3.svg';
import cg_mainnet_loopring from './images/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd.svg';
import cg_mainnet_renbtc from './images/0xeb4c2781e4eba804ce9a9803c67d0893436bb27d.png';
import cg_mainnet_sai from './images/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359.svg';
import cg_optimism_weth from './images/0x4200000000000000000000000000000000000006.svg';
import cg_optimism_usd_coin from './images/0x7f5c764cbc14f9669b88837ca1490cca17c31607.svg';
import cg_optimism_tether from './images/0x94b008aa00579c1307b0ef2c499ad98a8ce58e58.svg';
import cg_optimism_wrapped_bitcoin from './images/0x68f180fcce6836688e9084f035309e29bf0a2095.svg';
import cg_optimism_nusd from './images/0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9.webp';
import cg_optimism_optimism from './images/0x4200000000000000000000000000000000000042.webp';
import cg_optimism_havven from './images/0x8700daec35af8ff88c16bdf0418774cb3d7599b4.svg';
import cg_avalanche_wrapped_avax from './images/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7.webp';
import cg_avalanche_weth from './images/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab.svg';
import cg_avalanche_tether_avalanche_bridged_usdt_e from './images/0xc7198437980c041c805a1edcba50c1ce5db95118.svg';
import cg_avalanche_usd_coin_avalanche_bridged_usdc_e from './images/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664.svg';
import cg_avalanche_dai from './images/0xd586e7f844cea2f87f50152665bcbc2c279d8d70.svg';
import cg_avalanche_wrapped_bitcoin from './images/0x50b7545627a5162f82a992c33b87adc75187b218.svg';
import cg_avalanche_chainlink from './images/0x5947bb275c521040051d82396192181b413227a3.svg';
import cg_avalanche_usd_coin from './images/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e.svg';
import cg_avalanche_magic_internet_money from './images/0x130966628846bfd36ff31a822705796e8cb8c18d.svg';
import cg_avalanche_alpha_finance from './images/0x2147efff675e4a4ee1c2f918d181cdbd7a8e208f.webp';
import cg_avalanche_terrausd_wormhole from './images/0xb599c3590f42f8f995ecfa0f85d2980b76862fc1.webp';
import cg_avalanche_tether from './images/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7.svg';
import cg_fantom_wrapped_fantom from './images/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83.svg';
import cg_fantom_usd_coin from './images/0x04068da6c83afcfa0e13ba15a6696662335d5b75.svg';
import cg_fantom_cream_2 from './images/0x657a1861c15a3ded9af0b6799a195a249ebdcbc6.svg';
import cg_fantom_weth from './images/0x74b23882a30290451a17c44f4f05243b6b58c76d.svg';
import cg_fantom_wrapped_bitcoin from './images/0x321162cd933e2be498cd2267a90534a804051b11.svg';
import cg_fantom_dai from './images/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e.svg';
import cg_fantom_yearn_finance from './images/0x29b0da86e484e1c0029b56e817912d778ac0ec69.svg';
import cg_fantom_sushi from './images/0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc.svg';
import cg_fantom_aave from './images/0x6a07a792ab2965c72a5b8088d3a069a7ac3a993b.svg';
import cg_fantom_curve_dao_token from './images/0x1e4f97b9f9f913c46f1632781732927b9019c68b.webp';
import cg_fantom_band_protocol from './images/0x46e7628e8b4350b2716ab470ee0ba1fa9e76c6c5.svg';
import cg_fantom_keep3rv1 from './images/0x2a5062d22adcfaafbd5c541d4da82e4b450d4212.png';
import cg_fantom_nusd from './images/0x0e1694483ebb3b74d3054e383840c6cf011e518e.webp';
import cg_fantom_cover_protocol from './images/0xb01e8419d842beebf1b70a7b5f7142abbaf7159d.png';
import cg_fantom_hegic from './images/0x44b26e839eb3572c5e959f994804a5de66600349.png';
import cg_fantom_chainlink from './images/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8.svg';
import cg_fantom_havven from './images/0x56ee926bd8c72b2d5fa1af4d9e4cbb515a1e3adc.svg';
import cg_fantom_tether from './images/0x049d68029688eabf473097a2fc38ef61633a3c7a.svg';
import cg_fantom_magic_internet_money from './images/0x82f0b8b456c1a451378467398982d4834b6829c1.svg';
import cg_fantom_true_usd from './images/0x9879abdea01a879644185341f7af7d8343556b7a.svg';
import cg_fantom_frax from './images/0xdc301622e621166bd8e82f2ca0a26c13ad0be355.svg';
import cg_fantom_wrapped_ust from './images/0xe2d27f06f63d98b8e11b38b5b08a75d0c8dd62b9.webp';
import cg_avalanche_aave from './images/0x63a72806098bd3d9520cc43356dd78afe5d386d9.webp';
import cg_polygon_wrapped_bitcoin from './images/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6.svg';
import cg_polygon_usd_coin from './images/0x2791bca1f2de4661ed88a30c99a7a9449aa84174.svg';
import cg_polygon_dai from './images/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063.svg';
import cg_polygon_tether from './images/0xc2132d05d31c914a87c6611c10748aeb04b58e8f.svg';
import cg_polygon_wmatic from './images/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270.webp';
import cg_polygon_weth from './images/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619.svg';
import cg_polygon_aave from './images/0xd6df932a45c0f255f85145f286ea0b292b21c90b.svg';
import cg_mainnet_seur from './images/0xd71ecff9342a5ced620049e616c5035f1db98620.webp';
import cg_mainnet_stasis_eurs from './images/0xdb25f211ab05b1c97d595516f45794528a807ad8.webp';
import cg_arbitrum_one_stasis_eurs from './images/0xd22a58f79e9481d1a88e00c343885a588b34b68b.webp';
import cg_mainnet_musd from './images/0xe2f2a5c287993345a840db3b0845fbc70f5935a5.svg';
import cg_mainnet_compound_usd_coin from './images/0x39aa39c021dfbae8fac545936693ac917d5e7563.svg';
import cg_mainnet_cdai from './images/0x5d3a536e4d6dbd6114cc1ead35777bab948e3643.svg';
import cg_mainnet_compound_usdt from './images/0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9.svg';
import cg_mainnet_usdp from './images/0x1456688345527be1f37e9e627da0837d6f08c925.png';
import cg_mainnet_armor from './images/0x1337def16f9b486faed0293eb623dc8395dfe46a.webp';
import cg_mainnet_iron_bank_euro from './images/0x96e61422b6a9ba0e068b6c5add4ffabc6a4aae27.webp';
import cg_mainnet_tether_eurt from './images/0xc581b735a1688071a1746c968e0798d642ede491.png';
import cg_mainnet_iron_bank_chf from './images/0x1cc481ce2bd2ec7bf67d1be64d4878b16078f309.png';
import cg_mainnet_iron_bank_gbp from './images/0x69681f8fde45345c3870bcd5eaf4a05a60e7d227.png';
import cg_mainnet_cream_2 from './images/0x2ba592f78db6436527729929aaf6c908497cb200.svg';
import cg_mainnet_pepe from './images/0x6982508145454ce325ddbe47a25d4ec3d2311933.webp';
import cg_mainnet_cat_in_a_box_ether from './images/0x7690202e2c2297bcd03664e31116d1dffe7e3b73.webp';
import cg_arbitrum_one_olympus from './images/0xf0cb2dc0db5e6c66b9a70ac27b06b878da017028.png';
import cg_arbitrum_one_wrapped_steth from './images/0x5979d7b546e38e414f7e9822514be443a4800529.png';
import cg_avalanche_benqi_liquid_staked_avax from './images/0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be.webp';
import cg_avalanche_frax from './images/0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64.svg';
import cg_avalanche_bitcoin_avalanche_bridged_btc_b from './images/0x152b9d0fdc40c096757f570a51e494bd4b943e50.webp';
import cg_harmony_chainlink from './images/0x218532a12a389a4a92fc0c5fb22901d1c19198aa.svg';
import cg_harmony_usd_coin from './images/0x985458e523db3d53125813ed68c274899e9dfab4.webp';
import cg_harmony_dai from './images/0xef977d2f931c1978db5f6747666fa1eacb0d0339.svg';
import cg_harmony_wrapped_bitcoin from './images/0x3095c7557bcb296ccc6e363de01b760ba031f2d9.svg';
import cg_harmony_tether from './images/0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f.svg';
import cg_harmony_weth from './images/0x6983d1e6def3690c4d616b13597a09e6193ea013.webp';
import cg_harmony_aave from './images/0xcf323aad9e522b93f11c352caa519ad0e14eb40f.webp';
import cg_harmony_wrapped_one from './images/0xcf664087a5bb0237a0bad6742852ec6c8d69a27a.png';
import cg_polygon_chainlink from './images/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39.svg';
import cg_polygon_curve_dao_token from './images/0x172370d5cd63279efa6d502dab29171933a610af.webp';
import cg_polygon_sushi from './images/0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a.svg';
import cg_polygon_aavegotchi from './images/0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7.svg';
import cg_polygon_balancer from './images/0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3.svg';
import cg_polygon_jarvis_synthetic_euro from './images/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c.webp';
import cg_polygon_defipulse_index from './images/0x85955046df4668e1dd369d2de9f3aeb98dd2a369.svg';
import cg_polygon_stasis_eurs from './images/0xe111178a87a3bff0c8d18decba5798827539ae99.webp';
import cg_polygon_ageur from './images/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4.webp';
import cg_polygon_mimatic from './images/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png';
import cg_polygon_lido_staked_matic from './images/0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4.png';
import cg_polygon_stader_maticx from './images/0xfa68fb4628dff1028cfec22b4162fccd0d45efb6.png';
import cg_polygon_wrapped_steth from './images/0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd.png';
import cg_mainnet_sifu_vision from './images/0x29127fe04ffa4c32acac0ffe17280abd74eac313.webp';
import cg_mainnet_public_index_network from './images/0x3b79a28264fc52c7b4cea90558aa0b162f7faf57.png';
import cg_mainnet_boosted_lusd from './images/0xb9d7dddca9a4ac480991865efef82e01273f79c3.svg';
import cg_polygon_token_dforce_usd from './images/0xcf66eb3d546f0415b368d98a95eaf56ded7aa752.svg';
import cg_optimism_chainlink from './images/0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6.svg';
import cg_optimism_aave from './images/0x76fb31fb4af56892a25e32cfc43de717950c9278.svg';
import cg_optimism_wrapped_steth from './images/0x1f32b1c2345538c0c6f582fcb022739c4a194ebb.png';
import cg_optimism_liquity_usd from './images/0xc40f949f8a4e094d1b49a23ea9241d289b7b2819.svg';
import cg_optimism_mimatic from './images/0xdfa46478f9e5ea86d57387849598dbfb2e964b02.png';
import cg_arbitrum_one_mimatic from './images/0x3f56e0c36d275367b8c502090edf38289b3dea0d.png';
import cg_arbitrum_one_token_dforce_usd from './images/0x641441c631e2f909700d2f41fd87f0aa6a6b4edb.svg';
import cg_arbitrum_one_dforce_token from './images/0xae6aab43c4f3e0cea4ab83752c278f8debaba689.png';
import cg_arbitrum_one_frax from './images/0x17fc002b466eec40dae837fc4be5c67993ddbd6f.svg';
import cg_bsc_binance_usd from './images/0xe9e7cea3dedca5984780bafc599bd69add087d56.svg';
import cg_bsc_usd_coin from './images/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d.svg';
import cg_bsc_tether from './images/0x55d398326f99059ff775485246999027b3197955.svg';
import cg_bsc_dai from './images/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3.svg';
import cg_bsc_dforce_token from './images/0x4a9a2b2b04549c3927dd2c9668a5ef3fca473623.png';
import cg_bsc_binance_bitcoin from './images/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c.svg';
import cg_bsc_weth from './images/0x2170ed0880ac9a755fd29b2688956bd959f933f8.svg';
import cg_bsc_uniswap from './images/0xbf5140a22578168fd562dccf235e5d43a02ce9b1.svg';
import cg_bsc_cosmos from './images/0x0eb3a705fc54725037cc9e008bdede697f62f335.svg';
import cg_bsc_binance_peg_polkadot from './images/0x7083609fce4d1d8dc0c979aab8c869ea2c873402.png';
import cg_bsc_binance_peg_filecoin from './images/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153.svg';
import cg_bsc_binance_peg_cardano from './images/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47.svg';
import cg_bsc_token_dforce_usd from './images/0xb5102cee1528ce2c760893034a4603663495fd72.svg';
import cg_bsc_chainlink from './images/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd.svg';
import cg_bsc_binance_peg_xrp from './images/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe.png';
import cg_bsc_binance_peg_bitcoin_cash from './images/0x8ff795a6f4d97e7887c79bea79aba5cc76444adf.svg';
import cg_bsc_binance_peg_litecoin from './images/0x4338665cbb7b2485a8855a139b75d5e34ab0db94.svg';
import cg_bsc_pancakeswap_token from './images/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.svg';
import cg_mainnet_huobi_btc from './images/0x0316eb71485b0ab14103307bf65a021042c6d380.webp';
import cg_mainnet_dforce_token from './images/0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0.png';
import cg_mainnet_token_dforce_usd from './images/0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8.svg';
import cg_optimism_curve_dao_token from './images/0x0994206dfe8de6ec6920ff4d779b0d950605fb53.webp';
import cg_optimism_token_dforce_usd from './images/0xbfd291da8a403daaf7e5e9dc1ec0aceacd4848b9.svg';
import cg_optimism_dforce_token from './images/0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3.png';
import cg_polygon_dforce_token from './images/0x08c15fa26e519a78a666d19ce5c646d55047e0a3.png';
import cg_bsc_wbnb from './images/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.svg';
import cg_bsc_binance_peg_eos from './images/0x56b6fb708fc5732dec1afc8d8556423a2edccbd6.png';
import cg_bsc_alpha_finance from './images/0xa1faa113cbe53436df28ff0aee54275c13b40975.svg';
import cg_bsc_trust_wallet_token from './images/0x4b0f1812e5df2a09796481ff14017e6005508003.svg';
import cg_bsc_venus from './images/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63.webp';
import cg_bsc_vai from './images/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7.svg';
import cg_bsc_auto from './images/0xa184088a740c695e156f91f5cc086a06bb78b827.svg';
import cg_bsc_renbtc from './images/0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c.png';
import cg_bsc_binance_eth from './images/0x250632378e573c6be1ac2f97fcdf00515d0aa91b.png';
import cg_bsc_binance_peg_iotex from './images/0x9678e42cebeb63f23197d726b29b1cb20d0064e5.svg';
import cg_bsc_swipe from './images/0x47bead2563dcbf3bf2c9407fea4dc236faba485a.webp';
import cg_bsc_sushi from './images/0x947950bcc74888a40ffa2593c5798f11fc9124c4.svg';
import cg_polygon_quick from './images/0x831753dd7087cac61ab5644b308642cc1c33dc13.svg';
import cg_polygon_havven from './images/0x50b728d8d964fd00c2d0aad81718b71311fef68a.svg';
import cg_polygon_uniswap from './images/0xb33eaad8d922b1083446dc23f610c2567fb5180f.svg';
import cg_avalanche_benqi from './images/0x8729438eb15e2c8b576fcc6aecda6a148776c0f5.png';
import cg_avalanche_binance_usd from './images/0x9c9e5fd8bbc25984b178fdce6117defa39d2db39.svg';
import cg_fantom_scream from './images/0xe0654c8e6fd4d733349ac7e09f6f23da256bf475.webp';
import cg_fantom_tomb from './images/0x6c021ae822bea943b2e66552bde1d2696a53fbb7.webp';
import cg_fantom_spookyswap from './images/0x841fad6eae12c286d1fd18d1d525dffa75c7effe.webp';
import cg_fantom_fantom_usd from './images/0xad84341756bf337f5a0164515b1f6f993d194e1f.svg';
import cg_fantom_dola_usd from './images/0x3129662808bec728a27ab6a6b9afd3cbaca8a43c.webp';
import cg_fantom_beefy_finance from './images/0xd6070ae98b8069de6b494332d1a1a81b6179d960.svg';
import cg_fantom_spell_token from './images/0x468003b688943977e6130f4f68f23aad939a1040.webp';
import cg_mainnet_curve_fi_ydai_yusdc_yusdt_ytusd from './images/0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8.webp';
import cg_mainnet_ethlend from './images/0x80fb784b7ed66730e8b1dbd9820afd29931aab03.webp';
import cg_mainnet_meta from './images/0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2.png';
import cg_mainnet_serum from './images/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff.webp';
import cg_mainnet_swag_finance from './images/0x87edffde3e14c7a66c9b9724747a1c5696b742e6.svg';
import cg_mainnet_celsius_degree_token from './images/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.webp';
import cg_mainnet_binance_wrapped_btc from './images/0x9be89d2a4cd102d8fecc6bf9da793be995c22541.png';
import cg_mainnet_barnbridge from './images/0x0391d2021f89dc339f60fff84546ea23e337750f.png';
import cg_mainnet_huobi_fil from './images/0x9afb950948c2370975fb91a441f36fdc02737cd4.webp';
import cg_mainnet_husd from './images/0xdf574c24545e5ffecb9a659c229253d4111d87e1.svg';
import cg_mainnet_hegic from './images/0x584bc13c7d411c00c01a62e8019472de68768430.png';
import cg_mainnet_cover_protocol from './images/0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713.png';
import cg_mainnet_omisego from './images/0xd26114cd6ee289accf82350c8d8487fedb8a0c07.png';
import cg_mainnet_badger_sett_badger from './images/0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28.png';
import cg_mainnet_basis_cash from './images/0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a.webp';
import cg_mainnet_pickle_finance from './images/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.svg';
import cg_mainnet_origin_protocol from './images/0x8207c1ffc5b6804f6024322ccf34f29c3541ae26.png';
import cg_mainnet_akropolis from './images/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7.png';
import cg_mainnet_amp_token from './images/0xff20817765cb7f73d4bde2e66e067e58d11095c2.webp';
import cg_mainnet_wrapped_ust from './images/0xa47c8bf37f92abed4a126bda807a7b7498661acd.webp';
import cg_mainnet_wrapped_fantom from './images/0x4e15361fd6b4bb609fa63c81a2be19d873717870.webp';
import cg_mainnet_thorchain_erc20 from './images/0x3155ba85d5f96b2d030a4966af206230e46849cb.webp';
import cg_mainnet_rarible from './images/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.webp';
import cg_mainnet_saffron_finance from './images/0xb753428af26e81097e7fd17f40c88aaa3e04902c.svg';
import cg_mainnet_melon from './images/0xec67005c4e498ec7f55e092bd1d35cbc47c91892.webp';
import cg_mainnet_vesper_finance from './images/0x1b40183efb4dd766f11bda7a7c3ad8982e998421.png';
import cg_mainnet_trustswap from './images/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png';
import cg_mainnet_tokenlon from './images/0x0000000000095413afc295d19edeb1ad7b71c952.svg';
import cg_mainnet_yield_guild_games from './images/0x25f8087ead173b73d6e8b84329989a8eea16cf73.webp';
import cg_mainnet_axie_infinity from './images/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b.webp';
import cg_mainnet_crvusd from './images/0xf939e0a03fb07f59a73314e73794be0e57ac1b4e.webp';
import cg_bsc_stader_bnbx from './images/0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275.webp';
import cg_bsc_apeswap_finance from './images/0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95.webp';
import cg_arbitrum_one_usd_coin_2 from './images/0xaf88d065e77c8cc2239327c5edb3a432268e5831.svg';
import cg_mainnet_ousg from './images/0x1b19c19393e2d034d8ff31ff34c81252fcbbee92.webp';
import cg_mainnet_staked_frax_ether from './images/0xac3e018457b222d93114458476f3e3416abbe38f.svg';
import cg_mainnet_frax_price_index from './images/0x5ca135cb8527d76e932f34b5145575f9d8cbe08e.svg';
import cg_mainnet_curve_fi_frax_usdc from './images/0x3175df0976dfa876431c2e9ee6bc45b65d3473cc.webp';
import cg_mainnet_reth from './images/0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593.webp';
import cg_bsc_stafi_staked_bnb from './images/0xf027e525d491ef6ffcc478555fbb3cfabb3406a6.webp';
import cg_arbitrum_one_plvglp from './images/0x5326e71ff593ecc2cf7acae5fe57582d6e74cff1.webp';
import cg_mainnet_chain_2 from './images/0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18.webp';
import cg_arbitrum_one_governance_ohm from './images/0x8d9ba570d6cb60c7e3e0f31343efe75ab8e65fb1.webp';
import cg_arbitrum_one_sushi from './images/0xd4d42f0b6def4ce0383636770ef773390d85c61a.svg';
import cg_mainnet_tribe_2 from './images/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b.png';
import cg_mainnet_alchemix_usd from './images/0xbc6da0fe9ad5f3b0d58160288917aa56653660e9.webp';
import cg_mainnet_dola_usd from './images/0x865377367054516e17014ccded1e7d814edc9ce4.webp';
import cg_mainnet_shapeshift_fox_token from './images/0xc770eefad204b5180df6a14ee197d99d808ee52d.webp';
import cg_mainnet_gitcoin from './images/0xde30da39c46104798bb5aa3fe8b9e0e1f348163f.webp';
import cg_mainnet_rari_governance_token from './images/0xd291e7a03283640fdc51b121ac401383a46cc623.webp';
import cg_mainnet_swipe from './images/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.webp';
import cg_mainnet_strike from './images/0x74232704659ef37c08995e386a2e26cc27a8d7b1.png';
import cg_avalanche_joe from './images/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd.png';
import cg_polygon_dragons_quick from './images/0xf28164a485b0b2c90639e47b0f377b4a438a16b1.svg';
import cg_bsc_mask_network from './images/0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3.svg';
import cg_mainnet_yfii_finance from './images/0xa1d0e215a23d7030842fc67ce582a6afa3ccab83.webp';
import cg_arbitrum_one_silo_finance from './images/0x0341c0c0ec423328621788d4854119b97f44e391.png';
import cg_arbitrum_one_rocket_pool_eth from './images/0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8.webp';
import cg_optimism_rocket_pool_eth from './images/0x9bcef72be871e61ed4fbbc7630889bee758eb81d.webp';
import cg_optimism_usd_coin_2 from './images/0x0b2c639c533813f4aa9d7837caf62653d097ff85.svg';
import cg_mainnet_gho from './images/0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f.svg';
import cg_arbitrum_one_liquity_usd from './images/0x93b346b6bc2548da6a1e7d98e9a421b42541425b.svg';
import cg_mainnet_volta_club from './images/0x9b06f3c5de42d4623d7a2bd940ec735103c68a76.webp';
import cg_mainnet_savings_dai from './images/0x83f20f44975d03b1b09e64809b757c47f942beea.png';
import cg_mainnet_kyber_network_crystal from './images/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202.png';
import cg_mainnet_stargate_finance from './images/0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6.svg';
import cg_bsc_bridged_trueusd from './images/0x14016e85a25aeb13065688cafb43044c2ef86784.svg';
import cg_bsc_tron_bsc from './images/0xce7de646e7208a4ef112cb6ed5038fa6cc6b12e3.svg';
import cg_bsc_true_usd from './images/0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9.svg';
import cg_bsc_terrausd_wormhole from './images/0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a.webp';
import cg_bsc_wrapped_beacon_eth from './images/0xa2e3356610840701bdf5611a53974510ae27e2e1.webp';
import cg_bsc_aave from './images/0xfb6115445bff7b52feb98650c87f44907e58f802.svg';
import cg_bsc_matic_network from './images/0xcc42724c6683b7e57334c4e856f4c9965ed682bd.svg';
import cg_bsc_luna_wormhole from './images/0x156ab3346823b651294766e23e6cf87254d68962.webp';
import cg_bsc_binance_peg_dogecoin from './images/0xba2ae424d960c26247dd6c32edc70b295c744c43.svg';
import cg_mainnet_uwu_lend from './images/0x55c08ca52497e2f1534b59e2917bf524d4765257.webp';
import cg_arbitrum_one_radiant_capital from './images/0x3082cc23568ea640225c2467653db90e9250aaa0.webp';
import cg_mainnet_sifu_vision_2 from './images/0x8dd09822e83313adca54c75696ae80c5429697ff.webp';
import cg_moonbeam_atari from './images/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b.svg';
import cg_moonbeam_tether from './images/0x922d641a426dcffaef11680e5358f34d97d112e1.png';
import cg_moonbeam_usd_coin from './images/0xfa9343c3897324496a05fc75abed6bac29f8a40f.svg';
import cg_moonbeam_dai from './images/0x765277eebeca2e31912c9946eae1021199b39c61.svg';
import cg_moonbeam_dai_2 from './images/0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73.webp';
import cg_moonriver_binance_usd from './images/0x5d9ab5522c64e1f6ef5e3627eccc093f56167818.svg';
import cg_moonriver_anyswap from './images/0xb44a9b6905af7c801311e8f4e76932ee959c663c.webp';
import cg_moonriver_dai_3 from './images/0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d.svg';
import cg_moonriver_dai_4 from './images/0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844.svg';
import cg_moonriver_dai_5 from './images/0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c.svg';
import cg_moonriver_anyswap_2 from './images/0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8.svg';
import cg_moonriver_fsn from './images/0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c.webp';
import cg_harmony_binance_usd from './images/0xe176ebe47d621b984a73036b9da5d834411ef734.svg';
import cg_moonriver_frax from './images/0x1a93b23281cc1cde4c4741353f3064709a16197d.svg';
import cg_moonbeam_xcdot from './images/0xffffffff1fcacbd218edc0eba20fc2308c778080-moobeam.svg';
import cg_moonriver_xcksm from './images/0xffffffff1fcacbd218edc0eba20fc2308c778080-moonriver.webp';
import cg_moonbeam_weth from './images/0x30d2a9f5fdf90ace8c17952cbb4ee48a55d916a7.svg';
import cg_moonbeam_usd_coin_2 from './images/0x8f552a71efe5eefc207bf75485b356a0b3f01ec9.svg';
import cg_moonbeam_frax from './images/0x322e86852e492a7ee17f28a78c663da38fb33bfb.svg';
import cg_moonbeam_xcusdt from './images/0xffffffffea09fb06d082fd1275cd48b191cbcd1d.webp';
import cg_mainnet_ethereum_volatility_index_token from './images/0xc53342fd7575f572b0ff4569e31941a5b821ac76.webp';
import cg_mainnet_inverse_ethereum_volatility_index_token from './images/0x3a707d56d538e85b783e8ce12b346e7fb6511f90.webp';
import cg_mainnet_punk_vault_nftx from './images/0x269616d549d7e8eaa82dfb17028d0b212d11232a.webp';
import cg_mainnet_wizard_vault_nftx from './images/0x87931e7ad81914e7898d07c68f145fc0a553d8fb.webp';
import cg_mainnet_balancer_80_bal_20_weth from './images/0x5c6ee304399dbdb9c8ef030ab642b10820db8f56.webp';
import cg_mainnet_tracer_dao from './images/0x9c4a4204b79dd291d6b6571c5be8bbcd0622f050.webp';
import cg_arbitrum_one_arable_protocol from './images/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a.svg';
import cg_arbitrum_one_spell_token from './images/0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af.webp';
import cg_arbitrum_one_rocket_pool from './images/0xb766039cc6db368759c1e56b79affe831d0cc507.webp';
import cg_arbitrum_one_tracer_dao from './images/0xa72159fc390f0e3c6d415e658264c7c4051e9b87.webp';
import cg_arbitrum_one_handleusd from './images/0x8616e8ea83f048ab9a5ec513c9412dd2993bce3f.webp';
import cg_arbitrum_one_rmrk from './images/0xecf2adaff1de8a512f6e8bfe67a2c836edb25da3.webp';
import cg_bsc_planet_token from './images/0xca6d678e74f553f0e59cccc03ae644a3c2c5ee7d.webp';
import cg_bsc_synclub_staked_bnb from './images/0xb0b84d294e0c75a6abe60171b70edeb2efd14a1b.webp';
import cg_gnosis_weth from './images/0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1.svg';
import cg_gnosis_bridged_wrapped_steth_gnosis from './images/0x6c76971f98945ae98dd7d4dfca8711ebea946ea6.webp';
import cg_gnosis_gnosis from './images/0x9c58bacc331c9aa871afd802db6379a98e80cedb.png';
import cg_gnosis_wrapped_xdai from './images/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d.png';
import cg_gnosis_savings_xdai from './images/0xaf204776c7245bf4147c2612bf6e5972ee483701.webp';
import cg_gnosis_monerium_eur_money from './images/0xcb444e90d8198415266c6a2724b7900fb12fc56e.webp';
import cg_gnosis_usd_coin from './images/0xddafbb505ad214d7b80b1f830fccc89b60fb7a83.svg';
import cg_aurora_dai from './images/0xe3520349f477a5f6eb06107066048508498a291b.svg';
import cg_aurora_staked_near from './images/0x07f9f7f963c5cd2bbffd30ccfb964be114332e30.webp';
import cg_aurora_aurora_near from './images/0x8bec47865ade3b172a928df8f990bc7f2a3b9f79.svg';
import cg_aurora_trisolaris from './images/0xfa94348467f64d5a457f75f8bc40495d33c65abb.webp';
import cg_aurora_aurigami from './images/0x09c9d464b58d96837f8d8b6f4d9fe4ad408d3a4f.webp';
import cg_aurora_stader_nearx from './images/0xb39eeb9e168ef6c639f5e282fef1f6bc4dcae375.svg';
import cg_aurora_wrapped_near from './images/0xc42c30ac6cc15fac9bd938618bcaa1a1fae8501d.webp';
import cg_aurora_usd_coin from './images/0xb12bfca5a55806aaf64e99521918a4bf0fc40802.svg';
import cg_aurora_tether from './images/0x4988a896b1227218e4a686fde5eabdcabd91571f.svg';
import cg_aurora_wrapped_bitcoin from './images/0xf4eb217ba2454613b15dbdea6e5f22276410e89e.svg';
import cg_arbitrum_one_winr_protocol from './images/0xd77b108d4f6cefaa0cae9506a934e825becca46e.webp';
import cg_arbitrum_one_woo_network from './images/0xcafcd85d8ca7ad1e1c6f82f651fa15e33aefd07b.svg';
import cg_arbitrum_one_staked_ageur from './images/0x004626a008b1acdc4c74ab51644093b155e59a23.svg';
import cg_mainnet_wrapped_eeth from './images/0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee.svg';
import cg_mainnet_stader_ethx from './images/0xa35b1b31ce002fbf2058d22f30f95d405200a15b.webp';
import cg_manta_bridged_usd_coin_manta_pacific from './images/0xb73603c5d87fa094b7314c74ace2e64d165016fb.svg';
import cg_manta_bridged_wrapped_ether_manta_pacific from './images/0x0dc808adce2099a9f62aa87d9670745aba741746.svg';
import cg_manta_bridged_wrapped_bitcoin_manta_pacific from './images/0x305e88d809c9dc03179554bfbf85ac05ce8f18d6.svg';
import cg_manta_bridged_tether_manta_pacific from './images/0xf417f5a458ec102b90352f697d6e2ac3a3d2851f.svg';
import cg_manta_bridged_wrapped_steth_manta_pacific from './images/0x2fe3ad97a60eb7c79a976fc18bb5ffd07dd94ba5.png';
import cg_manta_stakestone_ether from './images/0xec901da9c68e90798bbbb74c11406a32a70652c3.png';
import cg_arbitrum_one_tbtc from './images/0x6c84a8f1c29108f47a79964b5fe888d4f4d0de40.png';
import cg_arbitrum_one_grai from './images/0x894134a25a5fac1c2c26f1d8fbf05111a3cb9487.svg';
import cg_zksync_era_zksync_bridged_usdc_zksync from './images/0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4.svg';
import cg_zksync_era_bridged_usdt from './images/0x493257fd37edb34451f62edf8d2a0c418852ba4c.svg';
import cg_zksync_era_weth from './images/0x5aea5775959fbc2557cc8789bc1bf90a239d9a91.svg';
import cg_zksync_era_liquity_usd from './images/0x503234f203fc7eb888eec8513210612a43cf6115.svg';
import cg_zksync_era_wrapped_bitcoin from './images/0xbbeb516fb02a01611cbbe0453fe3c580d7281011.svg';
import cg_zksync_era_onez from './images/0x90059c32eeeb1a2aa1351a58860d98855f3655ad.svg';
import cg_zksync_era_ezkalibur from './images/0x240f765af2273b0cab6caff2880d6d8f8b285fa4.webp';
import cg_zksync_era_velocore from './images/0x99bbe51be7cce6c8b84883148fd3d12ace5787f2.png';
import cg_zksync_era_mute from './images/0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42.png';
import cg_zksync_era_dai from './images/0x4b9eb6c0b6ea15176bbf62841c6b2a8a398cb656.svg';
// self icons
import self_mainnet_ar_nxm from './images/0x1337def18c680af1f9f45cbcab6309562975b1dd.webp';
import self_mainnet_dusd from './images/0x5bc25f649fc4e26069ddf4cf4010f9f706c23831.webp';
import self_mainnet_eth from './images/0x0000000000000000000000000000000000000000.svg';
import self_mainnet_ib_aud from './images/0xfafdf0c4c1cb09d430bf88c75d88bb46dae09967.png';
import self_mainnet_ib_jpy from './images/0x5555f75e3d5278082200fb451d1b6ba946d8e13b.png';
import self_mainnet_ib_krw from './images/0x95dfdc8161832e4ff7816ac4b6367ce201538253.png';
import self_mainnet_ren_fil from './images/0xd5147bc8e386d91cc5dbe72099dac6c9b99276f5.svg';
import self_mainnet_rep from './images/0x1985365e9f78359a9b6ad760e32412f4a445e862.svg';
import self_mainnet_rwa001 from './images/0x10b2aa5d77aa6484886d8e244f0686ab319a270d.svg';
import self_mainnet_rwa002 from './images/0xaaa760c2027817169d7c8db0dc61a2fb4c19ac23.svg';
import self_mainnet_rwa003 from './images/0x07f0a80ad7aeb7bfb7f139ea71b3c8f7e17156b9.svg';
import self_mainnet_rwa004 from './images/0x873f2101047a62f84456e3b2b13df2287925d3f9.svg';
import self_mainnet_rwa005 from './images/0x6db236515e90fc831d146f5829407746eddc5296.svg';
import self_mainnet_rwa007 from './images/0x078fb926b041a816facced3614cf1e4bc3c723bd.svg';
import self_mainnet_rwa008 from './images/0xb9737098b50d7c536b6416daeb32879444f59fca.svg';
import self_mainnet_rwa009 from './images/0x8b9734bbaa628bfc0c9f323ba08ed184e5b88da2.svg';
import self_mainnet_ste_crv from './images/0x06325440d014e39736583c165c2963ba99faf14e.svg';
import self_mainnet_uni_v2_1 from './images/0xbb2b8038a1640196fbe3e38816f3e67cba72d940.svg';
import self_mainnet_uni_v2_2 from './images/0x231b7589426ffe1b75405526fc32ac09d44364c4.svg';
import self_mainnet_uni_v2_3 from './images/0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc.svg';
import self_mainnet_uni_v2_4 from './images/0xd3d2e2692501a5c9ca623199d38826e513033a17.svg';
import self_mainnet_uni_v2_5 from './images/0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974.svg';
import self_mainnet_uni_v2_6 from './images/0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852.svg';
import self_mainnet_uni_v2_7 from './images/0xb20bd5d04be54f870d5c0d3ca85d82b34b836405.svg';
import self_mainnet_uni_v2_8 from './images/0xa478c2975ab1ea89e8196811f51a7b7ade33eb11.svg';
import self_mainnet_uni_v2_9 from './images/0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f.svg';
import self_mainnet_uni_v2_10 from './images/0xae461ca67b15dc8dc81ce7615e0320da1a9ab8d5.svg';
import self_mainnet_y3crv from './images/0x9ca85572e6a3ebf24dedd195623f188735a5179f.svg';
import self_avalanche_axellar_wrapped_ust from './images/0x260bbf5698121eb85e7a74f2e45e16ce762ebe11.webp';
import self_fantom_frax from './images/0xaf319e5789945197e365e7f7fbfc56b130523b33.svg';
import self_fantom_sfi from './images/0x924828a9fb17d47d0eb64b57271d10706699ff11.svg';
import self_arbitrum_one_eux from './images/0xc2125882318d04d266720b598d620f28222f3abd.svg';
import self_bsc_eux from './images/0x367c17d19fcd0f7746764455497d63c8e8b2bba3.svg';
import self_bsc_xbtc from './images/0x20ecc92f0a33e16e8cf0417dfc3f586cf597f3a9.svg';
import self_mainnet_eux from './images/0xb986f3a2d91d3704dc974a24fb735dcc5e3c1e70.svg';
import self_mainnet_xbtc from './images/0x527ec46ac094b399265d1d71eff7b31700aa655d.svg';
import self_polygon_eux from './images/0x448bbbdb706cd0a6ab74fa3d1157e7a33dd3a4a8.svg';
import self_polygon_fxs from './images/0x3e121107f6f22da4911079845a470757af4e1a1b.webp';
import self_bnb_native_coin from './images/bnb-native-coin.svg';
import self_harmony_native_coin from './images/harmony_native_coin.svg';
import self_moonbeam_native_coin from './images/moonbeam_native_coin.svg';
import self_moonriver_native_coin from './images/moonriver_native_coin.svg';
import self_manta_native_coin from './images/manta_native_coin.webp';
import self_bsc_xtz from './images/0x16939ef78684453bfdfb47825f8a5f714f12623a.png';
import self_bsc_yfi from './images/0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e.svg';
import self_bsc_bat from './images/0x101d82428437127bf1608f699cd651e6abf9766e.svg';
import self_bsc_renzec from './images/0x695fd30af473f2960e81dc9ba7cb67679d35edb7.svg';
import self_bsc_cream from './images/0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888.svg';
import self_bsc_band from './images/0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18.svg';
import self_fantom_fbtc from './images/0xe1146b9ac456fcbb60644c36fd3f868a9072fc6e.webp';
import self_fantom_feth from './images/0x658b0c7613e890ee50b8c4bc6a3f41ef411208ad.webp';
import self_mainnet_frxethcrv from './images/0xf43211935c781d5ca1a41d2041f397b8a7366c7a.png';
import self_bsc_r_atom from './images/0x1e5f6d5355ae5f1c5c687d3041c55f0aeec57eab.svg';
import self_bsc_t_fil from './images/0x7829a9810bb84b0e6827f21c81396125d76a2eab.svg';
import self_bsc_r_dot from './images/0x1dab2a526c8ac1ddea86838a7b968626988d33de.svg';
import self_bsc_r_eth from './images/0xa7a0a9fda65cd786b3dc718616cee25afb110544.svg';
import self_bsc_t_xtz from './images/0x8f1f15dcf4c70873faf1707973f6029dec4164b3.svg';
import self_bsc_t_atom from './images/0xa422885120a6e6bea36949737165d681759bb5bb.svg';
import self_mainnet_mayc from './images/0x60e4d786628fea6478f785a6d7e704777c86a7c6.webp';
import self_mainnet_bayc from './images/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d.png';
import self_mainnet_wpunks from './images/0xb7f7f6c52f2e2fdb1963eab30438024864c313f6.png';
import self_arbitrum_one_gmd_eth from './images/0x1e95a37be8a17328fbf4b25b9ce3ce81e271beb3.png';
import self_arbitrum_one_gmd_usdc from './images/0x3db4b7da67dd5af61cb9b3c70501b1bdb24b2c22.png';
import self_arbitrum_one_gmd_usdt from './images/0x34101fe647ba02238256b5c5a58aeaa2e532a049.png';
import self_arbitrum_one_gmd_btc from './images/0x147ff11d9b9ae284c271b2faae7068f4ca9bb619.png';
import self_avalanche_x_joe from './images/0x57319d41f71e81f3c65f2a47ca4e001ebafd4f33.png';
import self_mainnet_d_force_goldx from './images/0x355c665e101b9da58704a8fddb5feef210ef20c0.svg';
import self_mainnet_weth_yvault from './images/0xa9fe4601811213c340e850ea305481aff02f5b28.png';
import self_mainnet_curve_yusd from './images/0x4b5bfd52124784745c1071dcb244c6688d2533d3.png';
import self_mainnet_ib_btc from './images/0xc4E15973E6fF2A35cC804c2CF9D2a1b817a8b40F.png';
import self_mainnet_curve_yv_seth from './images/0x986b4aff588a109c09b50a03f42e4110e29d353f.png';
import self_mainnet_curve_yv_steth from './images/0xdcd90c7f6324cfa40d7169ef80b12031770b4325.png';
import self_mainnet_empty_set_dollar from './images/0x36F3FD68E7325a35EB768F1AedaAe9EA0689d723.png';
import self_mainnet_tbtc from './images/0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa.png';
import self_mainnet_curve_fi_yearn from './images/0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c.png';
import self_mainnet_yearn_wrapped_ether from './images/0xe1237aA7f535b0CC33Fd973D66cBf830354D16c7.png';
import self_mainnet_yv_curve_iron_bank from './images/0x27b7b1ad7288079A66d12350c828D3C00A6F07d7.png';
import self_arbitrum_one_joe_token from './images/0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07.png';
import self_arbitrum_one_pendle from './images/0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8.png';
import self_mainnet_spark_sai from './images/0x4DEDf26112B3Ec8eC46e7E31EA5e123490B05B8B.png';
import self_mainnet_xlon from './images/0xf88506B0F1d30056B9e5580668D5875b9cd30F23.png';
import self_bsc_nuls from './images/0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B.png';
import self_mainnet_sspell from './images/0x26fa3fffb6efe8c1e69103acb4044c26b9a106a9.webp';
import self_mainnet_sohm from './images/0x04f2694c8fcee23e8fd0dfea1d4f5bb8c352111f.png';
import self_bsc_usdd from './images/0xd17479997F34dd9156Deef8F95A52D81D265be9c.svg';
import self_bsc_ageur from './images/0x12f31B73D812C6Bb0d735a218c086d44D5fe5f89.svg';
import self_bsc_hay from './images/0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5.webp';
import self_bsc_raca from './images/0x12BB890508c125661E03b09EC06E404bc9289040.svg';
import self_bsc_floki from './images/0xfb5B838b6cfEEdC2873aB27866079AC55363D37E.svg';
import self_bsc_btt from './images/0x352Cb5E19b12FC216548a2677bD0fce83BaE434B.svg';
import self_bsc_win from './images/0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99.svg';
import self_bsc_ankr from './images/0xf307910A4c7bbc79691fD374889b36d8531B08e3.svg';
import self_bsc_bifi from './images/0xCa3F508B8e4Dd382eE878A314789373D80A5190A.svg';
import self_bsc_ankr_bnb from './images/0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827.svg';
import self_bsc_alpaca from './images/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F.svg';
import self_bsc_bsw from './images/0x965F527D9159dCe6288a2219DB51fc6Eef120dD1.png';
import self_bsc_stkbnb from './images/0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16.svg';
import self_mainnet_yam from './images/0x0AaCfbeC6a24756c20D41914F2caba817C0d8521.svg';
import self_mainnet_inv from './images/0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68.svg';
import self_mainnet_mph from './images/0x8888801aF4d980682e47f1A9036e589479e835C5.svg';
import self_mainnet_bnb from './images/0xB8c77482e45F1F44dE1745F52C74426C631bDD52.png';
import self_mainnet_socks from './images/0x23B608675a2B2fB1890d3ABBd85c5775c51691d5.png';
import self_mainnet_grt from './images/0xc944E90C64B2c07662A292be6244BDf05Cda44a7.svg';
import self_mainnet_yv_wbtc from './images/0xA696a63cc78DfFa1a63E9E50587C197387FF6C7E.png';
import self_mainnet_yv_usdc from './images/0x5f18C75AbDAe578b483E5F43f12a39cF75b973a9.png';
import self_mainnet_yv_dai from './images/0x19D3364A399d251E894aC732651be8B0E4e85001.png';
import self_mainnet_yv_weth from './images/0xa258C4606Ca8206D8aA700cE2143D7db854D168c.png';
import self_mainnet_yv_yfi from './images/0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1.png';
import self_mainnet_bed from './images/0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6.svg';
import self_mainnet_data from './images/0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1.png';
import self_mainnet_eth2x_fli from './images/0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD.png';
import self_mainnet_yv_boost from './images/0x9d409a0A012CFbA9B15F6D4B36Ac57A46966Ab9a.png';
import self_mainnet_cvx_crv from './images/0x62B9c7356A2Dc64a1969e19C23e4f579F9810Aa7.png';
import self_mainnet_yve_crv_dao from './images/0xc5bDdf9843308380375a611c18B50Fb9341f502A.png';
import self_mainnet_yv_link_crv from './images/0x96Ea6AF74Af09522fCB4c28C269C26F59a31ced6.png';
import self_mainnet_yv_curve_link from './images/0xf2db9a7c0ACd427A680D640F02d90f6186E71725.png';
import self_mainnet_volt from './images/0x559eBC30b0E58a45Cc9fF573f77EF1e5eb1b3E18.png';
import self_mainnet_pc_usdc from './images/0xD81b1A8B1AD00Baa2D6609E0BAE28A38713872f7.png';
import self_mainnet_digg from './images/0x798D1bE841a82a273720CE31c822C61a67a601C3.svg';
import self_mainnet_bve_cvx from './images/0xfd05D3C7fe2924020620A8bE4961bBaA747e6305.webp';
import self_mainnet_spc from './images/0x86ed939B500E121C0C5f493F399084Db596dAd20.svg';
import self_mainnet_alk from './images/0x6C16119B20fa52600230F074b349dA3cb861a7e3.svg';
import self_mainnet_ifarm from './images/0x1571eD0bed4D987fe2b498DdBaE7DFA19519F651.png';
import self_mainnet_idle from './images/0x875773784Af8135eA0ef43b5a374AaD105c5D39e.png';
import self_mainnet_toke from './images/0x2e9d63788249371f1DFC918a52f8d799F4a38C94.png';
import self_mainnet_sdt from './images/0x73968b9a57c6E53d41345FD57a6E6ae27d6CDB2F.svg';
import self_mainnet_x_sdt from './images/0xaC14864ce5A98aF3248Ffbf549441b04421247D3.svg';
import self_mainnet_bpt from './images/0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da.svg';
import self_mainnet_im_usd from './images/0x30647a72Dc82d7Fbb1123EA74716aB8A317Eac19.svg';
import self_mainnet_m_btc from './images/0x945Facb997494CC2570096c74b5F66A3507330a1.svg';
import self_mainnet_im_btc from './images/0x17d8cbb6bce8cee970a4027d1198f6700a7a6c24.svg';
import self_mainnet_dog from './images/0xbaac2b4491727d78d2b78815144570b9f2fe8899.webp';
import self_mainnet_rbn from './images/0x6123B0049F904d730dB3C36a31167D9d4121fA6B.svg';
import self_mainnet_iq from './images/0x579CEa1889991f68aCc35Ff5c3dd0621fF29b0C9.svg';
import self_mainnet_nfd from './images/0xdfdb7f72c1f195c5951a234e8db9806eb0635346.webp';
import self_mainnet_arai from './images/0xc9BC48c72154ef3e5425641a3c747242112a46AF.png';
import self_mainnet_xyz from './images/0x618679dF9EfCd19694BB1daa8D00718Eacfa2883.png';
import self_mainnet_ilv from './images/0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E.webp';
import self_mainnet_dep from './images/0x1A3496C18d558bd9C6C8f609E1B129f67AB08163.svg';
import self_mainnet_inst from './images/0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb.svg';
import self_mainnet_rare from './images/0xba5BDe662c17e2aDFF1075610382B9B691296350.png';
import self_mainnet_fwb from './images/0x35bD01FC9d6D5D81CA9E055Db88Dc49aa2c699A8.webp';
import self_mainnet_ff from './images/0x7E9D8f07A64e363e97A648904a89fb4cd5fB94CD.png';
import self_mainnet_bank from './images/0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198.png';
import self_mainnet_btc2x_fli from './images/0x0B498ff89709d3838a063f1dFA463091F9801c2b.webp';
import self_mainnet_ohm from './images/0x383518188c0c6d7730d91b2c03a03c837814a899.png';
import self_mainnet_apw from './images/0x4104b135DBC9609Fc1A9490E61369036497660c8.png';
import self_mainnet_forex from './images/0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B.png';
import self_mainnet_fx_eur from './images/0x116172B2482c5dC3E6f445C16Ac13367aC3FCd35.webp';
import self_mainnet_float from './images/0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9.svg';
import self_mainnet_float_bank from './images/0x24A6A37576377F63f194Caa5F518a60f45b42921.svg';
import self_mainnet_usdn from './images/0x674C6Ad92Fd080e4004b2312b45f796a192D27a0.png';
import self_mainnet_jrt from './images/0x8A9C67fee641579dEbA04928c4BC45F66e26343A.svg';
import self_mainnet_jeur from './images/0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b.webp';
import self_mainnet_slp from './images/0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25.webp';
import self_mainnet_dop from './images/0x6bB61215298F296C55b19Ad842D3Df69021DA2ef.webp';
import self_mainnet_vrn from './images/0x72377f31e30a405282b522d588AEbbea202b4f23.webp';
import self_mainnet_fodl from './images/0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3.png';
import self_mainnet_yaxis from './images/0x0adA190c81b814548ddC2F6AdC4a689ce7C1FE73.webp';
import self_mainnet_stk_aave from './images/0x4da27a545c0c5B758a6BA100e3a049001de870f5.svg';
import self_mainnet_kiro from './images/0xB1191F691A355b43542Bea9B8847bc73e7Abb137.svg';
import self_mainnet_keyfi from './images/0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052.svg';
import self_mainnet_xichi from './images/0x70605a6457b0a8fbf1eee896911895296eab467e.webp';
import self_mainnet_onebtc from './images/0xec4325f0518584f0774b483c215f65474eabd27f.png';
import self_mainnet_ichi from './images/0x903bef1736cddf2a537176cf3c64579c3867a881.png';
import self_mainnet_jup from './images/0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8.png';
import self_mainnet_babl from './images/0xf4dc48d260c93ad6a96c5ce563e70ca578987c74.png';
import self_mainnet_nexo from './images/0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206.png';
import self_mainnet_frax3crv_f from './images/0xd632f22692fac7611d2aa1c0d552930d43caed3b.png';
import self_mainnet_giv from './images/0x900db999074d9277c5da2a43f252d74366230da0.svg';
import self_mainnet_pdt from './images/0x375abb85c329753b1ba849a601438ae77eec9893.svg';
import self_mainnet_rad from './images/0x31c8eacbffdd875c74b94b077895bd78cf1e64a3.svg';
import self_mainnet_ethmaxy from './images/0x0fe20e0fa9c78278702b05c333cc000034bb69e2.png';
import self_mainnet_floor from './images/0xf59257e961883636290411c11ec5ae622d19455e.webp';
import self_mainnet_temple from './images/0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7.webp';
import self_mainnet_c98 from './images/0xae12c5930881c53715b369cec7606b70d8eb229f.svg';
import self_avalanche_mai from './images/0x5c49b268c9841aff1cc3b0a418ff5c3442ee3f3b.png';
import self_mainnet_rpl from './images/0xb4efd85c19999d84251304bda99e90b92300bd93.webp';
import self_moonbeam_wbtc from './images/0x1dc78acda13a8bc4408b207c9e48cdbc096d95e0.svg';
import self_moonbeam_usdc from './images/0x931715fee2d06333043d11f658c8ce934ac61d0c.svg';
import self_polygon_usdc from './images/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359.svg';
import self_mainnet_fx_php from './images/0x3d147cd9ac957b2a5f968de9d1c6b9d0872286a0.webp';
import self_arbitrum_one_fei from './images/0x4a717522566c7a09fd2774ccedc5a8c43c5f9fd2.svg';
import self_mainnet_fx_aud from './images/0x7e141940932e3d13bfa54b224cb4a16510519308.webp';
import self_arbitrum_one_brc from './images/0xb5de3f06af62d8428a8bf7b4400ea42ad2e0bc53.webp';
import self_mainnet_x_eth from './images/0x8d2cb35893c01fa8b564c84bd540c5109d9d278e.svg';
import self_bsc_x_eth from './images/0x463e3d1e01d048fdf872710f7f3745b5cdf50d0e.svg';
import self_bsc_fdusd from './images/0xc5f0f7b66764f6ec8c8dff7ba683102295e16409.png';
import self_polygon_ib_btc from './images/0x4eac4c4e9050464067d673102f8e24b2fcceb350.png';
import self_moonbeam_weth from './images/0xab3f0245b83feb11d15aaffefd7ad465a59817ed.svg';
import self_moonbeam_wbtc_2 from './images/0xe57ebd2d67b462e9926e04a8e33f01cd0d64346d.svg';
import self_moonbeam_busd from './images/0x692c57641fc054c2ad6551ccc6566eba599de1ba.svg';
import self_mainnet_creth2 from './images/0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd.webp';
import self_moonbeam_xc_usdc from './images/0xffffffff7d2b0b761af01ca8e25242976ac0ad7d.svg';
import self_mainnet_v_vsp from './images/0xba4cfe5741b357fa371b506e5db0774abfecf8fc.svg';
import self_mainnet_g_uni from './images/0x50379f632ca68d36e50cfbc8f78fe16bd1499d1e.svg';
import self_mainnet_g_uni_2 from './images/0xabddafb225e10b90d798bb8a886238fb835e2053.svg';
import self_mainnet_ib_zar from './images/0x81d66d255d47662b6b16f3c5bbfbb15283b05bc2.png';
import self_bsc_gold_x from './images/0xa05478b34fad86cc86f07eb6698c69ea60b266e3.svg';
import self_fantom_dei from './images/0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3.png';
import self_aurora_usn from './images/0x5183e1b1091804bc2602586919e6880ac1cf2896.svg';
import self_mainnet_one_fox from './images/0x03352d267951e96c6f7235037c5dfd2ab1466232.webp';
import self_mainnet_uni_v2 from './images/0x56feaccb7f750b997b36a68625c7c596f0b41a58.webp';
import self_mainnet_uni_v2_11 from './images/0x470e8de2ebaef52014a47cb5e6af86884947f08c.webp';
import self_mainnet_txjp from './images/0x961dd84059505d59f82ce4fb87d3c09bec65301d.webp';
import self_mannet_pebble from './images/0xdc98c5543f3004debfaad8966ec403093d0aa4a8.webp';
import self_mainnet_foxy from './images/0xdc49108ce5c57bc3408c3a5e95f3d864ec386ed3.webp';
import self_mainnet_fste_crv from './images/0xc27bfe32e0a934a12681c1b35acf0dba0e7460ba.webp';
import self_mainnet_fcrv_tricrypto from './images/0x33ed34dd7c40ef807356316b484d595ddda832ab.webp';
import self_mainnet_f_usdt from './images/0x053c80ea73dc6941f518a68e2fc52ac45bde7c9c.webp';
import self_mainnet_f_dai from './images/0xab7fa2b2985bccfc13c6d86b1d5a17486ab1e04c.webp';
import self_mainnet_f_weth from './images/0xfe09e53a81fe2808bc493ea64319109b5baa573e.webp';
import self_mainnet_swd from './images/0x1fd154b4d0e3753b714b511a53fe1fb72dc7ae1c.webp';
import self_mainnet_one_uni from './images/0x8290d7a64f25e6b5002d98367e8367c1b532b534.webp';
import self_mainnet_f_usdc from './images/0xf0358e8c3cd5fa238a29301d0bea3d63a17bedbe.webp';
import self_mainnet_bb_a_usd from './images/0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb2.webp';
import self_mainnet_wdg from './images/0x58ca23f163391ecde8625786643c279968fae4ec.webp';
import self_mainnet_crv_3crypto from './images/0xc4ad29ba4b3c580e6d59105fff484999997675ff.webp';
import self_mainnet_insure from './images/0xd83ae04c9ed29d6d3e6bf720c71bc7beb424393e.webp';
import self_mainnet_g_uni_3 from './images/0x3d1556e84783672f2a3bd187a592520291442539.svg';
import self_mainnet_btcv from './images/0x51b0bcbeff204b39ce792d1e16767fe6f7631970.webp';
import self_mainnet_g_uni_4 from './images/0xcf84a3dc12319531e3debd48c86e68eaeaff224a.svg';
import self_mainnet_ot_c_dai_29dec2022 from './images/0x3d4e7f52efafb9e0c70179b688fc3965a75bcfea.webp';
import self_mainnet_b_digg from './images/0x7e7e112a68d8d2e221e11047a72ffc1065c38e1a.svg';
import self_mainnet_ot_a_usdc_29dec2022 from './images/0x8fcb1783bf4b71a51f702af0c266729c4592204a.webp';
import self_bsc_trx from './images/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b.webp';
import self_mainnet_vusd from './images/0x677ddbd918637e5f2c79e164d402454de7da8619.webp';
import self_mainnet_i_btcv from './images/0x2590f1fd14ef8bb0a46c7a889c4cbc146510f9c3.webp';
import self_mainnet_met from './images/0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e.webp';
import self_manta_tia from './images/0x6fae4d9935e2fcb11fc79a64e917fb2bf14dafaa.webp';
import self_manta_wusdm from './images/0xbdad407f77f44f7da6684b416b1951eca461fb07.webp';
import self_mainnet_pyusd from './images/0x6c3ea9036406852006290770bedfcaba0e23a0e8.svg';
import self_mainnet_fxb_20241231 from './images/0xf8fde8a259a3698902c88bdb1e13ff28cd7f6f09.png';
import self_mainnet_fxb_20261231 from './images/0x76237bcfdbe8e06fb774663add96216961df4ff3.png';
import self_mainnet_fxb_20240630 from './images/0x0de54cfdfed8005176f8b7a9d5438b45c4f1df1e.png';
import self_zksync_era_pepe from './images/0xfd282f16a64c6d304ac05d1a58da15bed0467c71.svg';
import self_zksync_era_cbeth from './images/0x75af292c1c9a37b3ea2e6041168b4e48875b9ed5.svg';
import self_zksync_era_busd from './images/0x2039bb4116b4efc145ec4f0e2ea75012d6c0f181.svg';
import self_mainnet_wusdm from './images/0x57f5e098cad7a3d1eed53991d4d66c45c9af7812.webp';
import self_mainnet_monerium_eur_money from './images/0x3231cb76718cdef2155fc47b5286d82e6eda273f.webp';
import self_arbitrum_one_wrapped_eeth from './images/0x35751007a407ca6feffe80b3cb397736d2cf4dbe.svg';
import self_mainnet_ezeth from './images/0xbf5495efe5db9ce00f80364c8b423567e58d2110.png';
import self_mainnet_oseth from './images/0xf1c9acdc66974dfb6decb12aa385b9cd01190e38.webp';
import self_mainnet_usde from './images/0x4c9edd5852cd905f086c759e8383e09bff1e68b3.webp';
import self_mainnet_apxeth from './images/0x9ba021b0a9b958b5e75ce9f6dff97c7ee52cb3e6.webp';
import self_mainnet_susde from './images/0x9d39a5de30e57443bff2a8307a4256c8797a3497.webp';
import self_mainnet_wbc3m from './images/0x95d7337d43340e2721960dc402d9b9117f0d81a2.svg';
import self_mainnet_usda from './images/0x0000206329b97db379d5e1bf586bbdb969c63274.svg';
import self_mainnet_wbib1 from './images/0xabc56186c2df63c338e04c9c9b9814e27983a5a9.svg';
import self_mainnet_wbib1_2 from './images/0xca2a7068e551d5c4482eb34880b194e4b945712f.svg';
import self_mainnet_mtbill from './images/0xdd629e5241cbc5919847783e6c96b2de4754e438.svg';
import self_zksync_era_cake from './images/0x3a287a06c66f9e95a56327185ca2bdf5f031cecd.svg';
import self_zksync_era_wsteth from './images/0x703b52f2b28febcb60e1372858af5b18849fe867.png';
import self_manta_matic from './images/0x0f52a51287f9b3894d73df05164d0ee2533ccbb4.svg';
import self_mainnet_pufeth from './images/0xd9a442856c234a39a81a089c06451ebaa4306a72.webp';
import self_mainnet_rseth from './images/0xa1290d69c65a6fe4df752f95823fae25cb99e5a7.webp';
import self_base_USDbC from './images/0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca.svg';
import self_base_cbETH from './images/0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22.svg';
import self_base_wstETH from './images/0xc1cba3fcea344f92d9239c08c0568f6f2f0ee452.svg';
import self_base_usdc from './images/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913.svg';
import self_base_weETH from './images/0x04c0599ae5a44757c0af6f9ec3b93da8976c150a.svg';
import self_linea_usdc from './images/0x176211869ca2b568f2a7d4ee941e073a821ee1ff.svg';
import self_linea_usdt from './images/0xa219439258ca9da29e9cc4ce5596924745e12b93.svg';
import self_linea_weth from './images/0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f.svg';
import self_linea_mai from './images/0xf3b001d64c656e30a62fbaaca003b1336b4ce12a.png';
import self_linea_ez_eth from './images/0x2416092f143378750bb29b79ed961ab195cceea5.png';
import self_linea_wbtc from './images/0x3aab2285ddcddad8edf438c1bab47e1a9d05a9b4.svg';
import self_linea_wrc_eth from './images/0xd2671165570f41bbb3b0097893300b6eb6101e6c.svg';
import self_linea_we_eth from './images/0x1bf74c010e6320bab11e2e5a532b5ac15e0b8aa6.svg';
import self_linea_uni_eth from './images/0x15eefe5b297136b8712291b632404b66a8ef4d25.svg';
import self_linea_s_usd_e from './images/0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2.webp';
import self_linea_dai from './images/0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5.svg';
import self_linea_usde from './images/0x5d3a1ff2b6bab83b63cd9ad0787074081a52ef34.webp';
import self_linea_stone from './images/0x93f4d0ab6a8b4271f4a28db399b5e30612d21116.png';
import self_linea_wst_eth from './images/0xb5bedd42000b71fdde22d3ee8a79bd49a568fc8f.png';
import self_linea_frx_eth from './images/0xecc68d0451e20292406967fe7c04280e5238ac7d.svg';
import self_linea_in_eth from './images/0x5a7a183b6b44dc4ec2e3d2ef43f98c5152b1d76d.svg';
import self_linea_lyu from './images/0xb20116ee399f15647bb1eef9a74f6ef3b58bc951.svg';
import self_linea_solve_btc_m from './images/0x5ffce65a40f6d3de5332766fff6a28bf491c868c.svg';
import self_linea_m_btc from './images/0xe4d584ae9b753e549cae66200a6475d2f00705f7.svg';

export const tokensIcons: CIMap<string, StaticImageData | CIMap<string, StaticImageData>> =
  CIMap.fromRecord({
    '0xf4d2888d29d722226fafa5d9b24f9164c092421e': cg_mainnet_looksrare,
    '0xd7c9f0e536dc865ae858b0c0453fe76d13c3beac': cg_mainnet_xai,
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': cg_mainnet_weth,
    '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2': cg_mainnet_maker,
    '0x5283d291dbcf85356a21ba090e6db59121208b44': cg_mainnet_blur,
    '0x0d438f3b5175bebc262bf23753c1e53d03432bde': cg_mainnet_wrapped_nxm,
    '0xbc396689893d065f41bc2c6ecbee5e0085233447': cg_mainnet_perpetual_protocol,
    '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9': cg_mainnet_ftx_token,
    '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0': cg_mainnet_matic_network,
    '0x3472a5a71965499acd81997a54bba8d852c6e53d': cg_mainnet_badger_dao,
    '0x419d0d8bdd9af5e606ae2232ed285aff190e711b': cg_mainnet_funfair,
    '0xbe9895146f7af43049ca1c1ae358b0541ea49704': cg_mainnet_coinbase_wrapped_staked_eth,
    '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44': cg_mainnet_keep3rv1,
    '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': cg_mainnet_wrapped_bitcoin,
    '0x5e8422345238f34275888049021821e8e08caa1f': cg_mainnet_frax_ether,
    '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919': cg_mainnet_rai,
    '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': cg_mainnet_aave,
    '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984': cg_mainnet_uniswap,
    '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': cg_mainnet_havven,
    '0x3845badade8e6dff049820680d1f14bd3903a5d0': cg_mainnet_the_sandbox,
    '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0': cg_mainnet_wrapped_steth,
    '0x92d6c1e31e14520e676a687f0a93788b716beff5': cg_mainnet_dydx,
    '0xdbdb4d16eda451d0503b854cf79d55697f90c8df': cg_mainnet_alchemix,
    '0x967da4048cd07ab37855c090aaf366e4ce1b9f48': cg_mainnet_ocean_protocol,
    '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d': cg_mainnet_liquity,
    '0xd33526068d116ce69f19a9ee46f0bd304f21a51f': cg_mainnet_rocket_pool,
    '0xa0246c9032bc3a600820415ae600c6388619a14d': cg_mainnet_harvest_finance,
    '0xc0c293ce456ff0ed870add98a0828dd4d2903dbf': cg_mainnet_aura_finance,
    '0x853d955acef822db058eb8505911ed77f175b99e': cg_mainnet_frax,
    '0x72b886d09c117654ab7da13a14d603001de0b777': cg_mainnet_xdefi,
    '0x090185f2135308bad17527004364ebcc2d37e5f6': cg_mainnet_spell_token,
    '0x8798249c2e607446efb7ad49ec89dd1865ff4272': cg_mainnet_xsushi,
    '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5': cg_mainnet_bitdao,
    '0x6b175474e89094c44da98b954eedeac495271d0f': cg_mainnet_dai,
    '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776': cg_mainnet_nftx,
    '0xfe2e637202056d30016725477c5da089ab0a043a': cg_mainnet_seth2,
    '0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6': cg_mainnet_usdd,
    '0x5a98fcbea516cf06857215779fd812ca3bef1b32': cg_mainnet_lido_dao,
    '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c': cg_mainnet_bancor,
    '0xe80c0cd204d654cebe8dd64a4857cab6be8345a3': cg_mainnet_jpeg_d,
    '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8': cg_mainnet_ageur,
    '0x6f80310ca7f2c654691d1383149fa1a57d8ab1f8': cg_mainnet_silo_finance,
    '0x57ab1ec28d129707052df4df418d58a2d46d5f51': cg_mainnet_nusd,
    '0x04fa0d235c4abf4bcf4787af4cf447de572ef828': cg_mainnet_uma,
    '0xba100000625a3754423978a60c9317c58a424e3d': cg_mainnet_balancer,
    '0x0d8775f648430679a709e98d2b0cb6250d2887ef': cg_mainnet_basic_attention_token,
    '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': cg_mainnet_yearn_finance,
    '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b': cg_mainnet_convex_finance,
    '0x0000000000085d4780b73119b644ae5ecd22b376': cg_mainnet_true_usd,
    '0xd533a949740bb3306d119cc777fa900ba034cd52': cg_mainnet_curve_dao_token,
    '0x4a220e6096b25eadb88358cb44068a3248254675': cg_mainnet_quant_network,
    '0x111111111117dc0aa78b770fa6a738034120c302': cg_mainnet_1inch,
    '0xae78736cd615f374d3085123a210448e74fc6393': cg_mainnet_rocket_pool_eth,
    '0x4fabb145d64652a948d72533023f6e7a623c7c53': cg_mainnet_binance_usd,
    '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff': cg_mainnet_immutable_x,
    '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72': cg_mainnet_ethereum_name_service,
    '0x5f98805a4e8be255a32880fdec7f6728c6568ba0': cg_mainnet_liquity_usd,
    '0x6810e776880c02933d47db1b9fc05908e5386b96': cg_mainnet_gnosis,
    '0x0ab87046fbb341d058f17cbc4c1133f25a20a52f': cg_mainnet_governance_ohm,
    '0x0f5d2fb29fb7d3cfee444a200298f468908cc942': cg_mainnet_decentraland,
    '0x514910771af9ca656af840dff83e8264ecf986ca': cg_mainnet_chainlink,
    '0xdac17f958d2ee523a2206206994597c13d831ec7': cg_mainnet_tether,
    '0xc00e94cb662c3520282e6f5717214004a7f26888': cg_mainnet_compound_governance_token,
    '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b': cg_mainnet_crypto_com_chain,
    '0x4d224452801aced8b2f0aebe155379bb5d594381': cg_mainnet_apecoin,
    '0x8e870d67f660d95d5be530380d0ec0bd388289e1': cg_mainnet_paxos_standard,
    '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd': cg_mainnet_gemini_dollar,
    '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce': cg_mainnet_shiba_inu,
    '0xe41d2489571d322189246dafa5ebde1f4699f498': cg_mainnet_0x,
    '0x4691937a7508860f876c9c0a2a617e7d9e945d4b': cg_mainnet_woo_network,
    '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0': cg_mainnet_frax_share,
    '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5': cg_mainnet_olympus,
    '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3': cg_mainnet_magic_internet_money,
    '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': cg_mainnet_sushi,
    '0x45804880de22913dafe09f4980848ece6ecbaf78': cg_mainnet_pax_gold,
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': cg_mainnet_usd_coin,
    '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c': cg_mainnet_enjincoin,
    '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': cg_arbitrum_one_weth,
    '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0': cg_arbitrum_one_uniswap,
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': cg_arbitrum_one_usd_coin,
    '0x912ce59144191c1204e64559fe8253a0e49e6548': cg_arbitrum_one_arbitrum,
    '0x031d35296154279dc1984dcd93e392b1f946737b': cg_arbitrum_one_cap,
    '0x5575552988a3a80504bbaeb1311674fcfd40ad4b': cg_arbitrum_one_sperax,
    '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9': cg_arbitrum_one_tether,
    '0x6694340fc020c5e6b96567843da2df01b2ce1eb6': cg_arbitrum_one_stargate_finance,
    '0x539bde0d7dbd336b79148aa742883198bbf60342': cg_arbitrum_one_magic,
    '0x51fc0f6660482ea73330e414efd7808811a57fa2': cg_arbitrum_one_premia,
    '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': cg_arbitrum_one_wrapped_bitcoin,
    '0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55': cg_arbitrum_one_dopex,
    '0x10393c20975cf177a3513071bc110f7962cd67da': cg_arbitrum_one_jones_dao,
    '0xf97f4df75117a78c1a5a0dbb814af92458539fb4': cg_arbitrum_one_chainlink,
    '0x32eb7902d4134bf98a28b963d26de779af92a212': cg_arbitrum_one_dopex_rebate_token,
    '0x65c936f008bc34fe819bce9fa5afd9dc2d49977f': cg_arbitrum_one_y2k,
    '0x51318b7d00db7acc4026c88c3952b66278b6a67f': cg_arbitrum_one_plutusdao,
    '0x088cd8f5ef3652623c22d48b1605dcfe860cd704': cg_arbitrum_one_vela_token,
    '0x3d9907f9a368ad0a51be60f7da3b97cf940982d8': cg_arbitrum_one_camelot_token,
    '0x1622bf67e6e5747b81866fe0b85178a93c7f86e3': cg_arbitrum_one_umami_finance,
    '0x11cdb42b0eb46d95f990bedd4695a6e3fa034978': cg_arbitrum_one_curve_dao_token,
    '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a': cg_arbitrum_one_gmx,
    '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': cg_arbitrum_one_dai,
    '0xd74f5255d557944cf7dd0e45ff521520002d5748': cg_arbitrum_one_sperax_usd,
    '0x4945970efeec98d393b4b979b9be265a3ae28a8b': cg_arbitrum_one_gmd_protocol,
    '0x18c11fd286c5ec11c3b683caa813b77f5163a122': cg_arbitrum_one_gains_network,
    '0x408e41876cccdc0f92210600ef50372656052a38': cg_mainnet_republic_protocol,
    '0xdd974d5c2e2928dea5f71b9825b8b646686bd200': cg_mainnet_kyber_network,
    '0xd46ba6d942050d489dbd938a2c909a5d5039a161': cg_mainnet_ampleforth,
    '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b': cg_mainnet_defipulse_index,
    '0x956f47f50a910163d8bf957cf5846d573e7f87ca': cg_mainnet_fei_usd,
    '0xae7ab96520de3a18e5e111b5eaab095312d7fe84': cg_mainnet_staked_ether,
    '0xa693b19d2931d498c5b318df961919bb4aee87a5': cg_mainnet_terrausd_wormhole,
    '0x028171bca77440897b824ca71d1c56cac55b68a3': cg_mainnet_aave_dai,
    '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd': cg_mainnet_loopring,
    '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d': cg_mainnet_renbtc,
    '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359': cg_mainnet_sai,
    '0x4200000000000000000000000000000000000006': cg_optimism_weth,
    '0x7f5c764cbc14f9669b88837ca1490cca17c31607': cg_optimism_usd_coin,
    '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58': cg_optimism_tether,
    '0x68f180fcce6836688e9084f035309e29bf0a2095': cg_optimism_wrapped_bitcoin,
    '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9': cg_optimism_nusd,
    '0x4200000000000000000000000000000000000042': cg_optimism_optimism,
    '0x8700daec35af8ff88c16bdf0418774cb3d7599b4': cg_optimism_havven,
    '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7': cg_avalanche_wrapped_avax,
    '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab': cg_avalanche_weth,
    '0xc7198437980c041c805a1edcba50c1ce5db95118': cg_avalanche_tether_avalanche_bridged_usdt_e,
    '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664': cg_avalanche_usd_coin_avalanche_bridged_usdc_e,
    '0xd586e7f844cea2f87f50152665bcbc2c279d8d70': cg_avalanche_dai,
    '0x50b7545627a5162f82a992c33b87adc75187b218': cg_avalanche_wrapped_bitcoin,
    '0x5947bb275c521040051d82396192181b413227a3': cg_avalanche_chainlink,
    '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e': cg_avalanche_usd_coin,
    '0x130966628846bfd36ff31a822705796e8cb8c18d': cg_avalanche_magic_internet_money,
    '0x2147efff675e4a4ee1c2f918d181cdbd7a8e208f': cg_avalanche_alpha_finance,
    '0xb599c3590f42f8f995ecfa0f85d2980b76862fc1': cg_avalanche_terrausd_wormhole,
    '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7': cg_avalanche_tether,
    '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83': cg_fantom_wrapped_fantom,
    '0x04068da6c83afcfa0e13ba15a6696662335d5b75': cg_fantom_usd_coin,
    '0x657a1861c15a3ded9af0b6799a195a249ebdcbc6': cg_fantom_cream_2,
    '0x74b23882a30290451a17c44f4f05243b6b58c76d': cg_fantom_weth,
    '0x321162cd933e2be498cd2267a90534a804051b11': cg_fantom_wrapped_bitcoin,
    '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e': cg_fantom_dai,
    '0x29b0da86e484e1c0029b56e817912d778ac0ec69': cg_fantom_yearn_finance,
    '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc': cg_fantom_sushi,
    '0x6a07a792ab2965c72a5b8088d3a069a7ac3a993b': cg_fantom_aave,
    '0x1e4f97b9f9f913c46f1632781732927b9019c68b': cg_fantom_curve_dao_token,
    '0x46e7628e8b4350b2716ab470ee0ba1fa9e76c6c5': cg_fantom_band_protocol,
    '0x2a5062d22adcfaafbd5c541d4da82e4b450d4212': cg_fantom_keep3rv1,
    '0x0e1694483ebb3b74d3054e383840c6cf011e518e': cg_fantom_nusd,
    '0xb01e8419d842beebf1b70a7b5f7142abbaf7159d': cg_fantom_cover_protocol,
    '0x44b26e839eb3572c5e959f994804a5de66600349': cg_fantom_hegic,
    '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8': cg_fantom_chainlink,
    '0x56ee926bd8c72b2d5fa1af4d9e4cbb515a1e3adc': cg_fantom_havven,
    '0x049d68029688eabf473097a2fc38ef61633a3c7a': cg_fantom_tether,
    '0x82f0b8b456c1a451378467398982d4834b6829c1': cg_fantom_magic_internet_money,
    '0x9879abdea01a879644185341f7af7d8343556b7a': cg_fantom_true_usd,
    '0xdc301622e621166bd8e82f2ca0a26c13ad0be355': cg_fantom_frax,
    '0xe2d27f06f63d98b8e11b38b5b08a75d0c8dd62b9': cg_fantom_wrapped_ust,
    '0x63a72806098bd3d9520cc43356dd78afe5d386d9': cg_avalanche_aave,
    '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6': cg_polygon_wrapped_bitcoin,
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': cg_polygon_usd_coin,
    '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063': cg_polygon_dai,
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f': cg_polygon_tether,
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': cg_polygon_wmatic,
    '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619': cg_polygon_weth,
    '0xd6df932a45c0f255f85145f286ea0b292b21c90b': cg_polygon_aave,
    '0xd71ecff9342a5ced620049e616c5035f1db98620': cg_mainnet_seur,
    '0xdb25f211ab05b1c97d595516f45794528a807ad8': cg_mainnet_stasis_eurs,
    '0xe2f2a5c287993345a840db3b0845fbc70f5935a5': cg_mainnet_musd,
    '0x39aa39c021dfbae8fac545936693ac917d5e7563': cg_mainnet_compound_usd_coin,
    '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643': cg_mainnet_cdai,
    '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9': cg_mainnet_compound_usdt,
    '0x1456688345527be1f37e9e627da0837d6f08c925': cg_mainnet_usdp,
    '0x1337def16f9b486faed0293eb623dc8395dfe46a': cg_mainnet_armor,
    '0x96e61422b6a9ba0e068b6c5add4ffabc6a4aae27': cg_mainnet_iron_bank_euro,
    '0xc581b735a1688071a1746c968e0798d642ede491': cg_mainnet_tether_eurt,
    '0x1cc481ce2bd2ec7bf67d1be64d4878b16078f309': cg_mainnet_iron_bank_chf,
    '0x69681f8fde45345c3870bcd5eaf4a05a60e7d227': cg_mainnet_iron_bank_gbp,
    '0x2ba592f78db6436527729929aaf6c908497cb200': cg_mainnet_cream_2,
    '0x6982508145454ce325ddbe47a25d4ec3d2311933': cg_mainnet_pepe,
    '0x7690202e2c2297bcd03664e31116d1dffe7e3b73': cg_mainnet_cat_in_a_box_ether,
    '0xf0cb2dc0db5e6c66b9a70ac27b06b878da017028': cg_arbitrum_one_olympus,
    '0x5979d7b546e38e414f7e9822514be443a4800529': cg_arbitrum_one_wrapped_steth,
    '0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be': cg_avalanche_benqi_liquid_staked_avax,
    '0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64': cg_avalanche_frax,
    '0x152b9d0fdc40c096757f570a51e494bd4b943e50': cg_avalanche_bitcoin_avalanche_bridged_btc_b,
    '0x218532a12a389a4a92fc0c5fb22901d1c19198aa': cg_harmony_chainlink,
    '0x985458e523db3d53125813ed68c274899e9dfab4': cg_harmony_usd_coin,
    '0xef977d2f931c1978db5f6747666fa1eacb0d0339': cg_harmony_dai,
    '0x3095c7557bcb296ccc6e363de01b760ba031f2d9': cg_harmony_wrapped_bitcoin,
    '0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f': cg_harmony_tether,
    '0x6983d1e6def3690c4d616b13597a09e6193ea013': cg_harmony_weth,
    '0xcf323aad9e522b93f11c352caa519ad0e14eb40f': cg_harmony_aave,
    '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a': cg_harmony_wrapped_one,
    '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39': cg_polygon_chainlink,
    '0x172370d5cd63279efa6d502dab29171933a610af': cg_polygon_curve_dao_token,
    '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a': cg_polygon_sushi,
    '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7': cg_polygon_aavegotchi,
    '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3': cg_polygon_balancer,
    '0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c': cg_polygon_jarvis_synthetic_euro,
    '0x85955046df4668e1dd369d2de9f3aeb98dd2a369': cg_polygon_defipulse_index,
    '0xe111178a87a3bff0c8d18decba5798827539ae99': cg_polygon_stasis_eurs,
    '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4': cg_polygon_ageur,
    '0xa3fa99a148fa48d14ed51d610c367c61876997f1': cg_polygon_mimatic,
    '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4': cg_polygon_lido_staked_matic,
    '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6': cg_polygon_stader_maticx,
    '0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd': cg_polygon_wrapped_steth,
    '0x29127fe04ffa4c32acac0ffe17280abd74eac313': cg_mainnet_sifu_vision,
    '0x3b79a28264fc52c7b4cea90558aa0b162f7faf57': cg_mainnet_public_index_network,
    '0xb9d7dddca9a4ac480991865efef82e01273f79c3': cg_mainnet_boosted_lusd,
    '0xcf66eb3d546f0415b368d98a95eaf56ded7aa752': cg_polygon_token_dforce_usd,
    '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6': cg_optimism_chainlink,
    '0x76fb31fb4af56892a25e32cfc43de717950c9278': cg_optimism_aave,
    '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb': cg_optimism_wrapped_steth,
    '0xc40f949f8a4e094d1b49a23ea9241d289b7b2819': cg_optimism_liquity_usd,
    '0xdfa46478f9e5ea86d57387849598dbfb2e964b02': cg_optimism_mimatic,
    '0x3f56e0c36d275367b8c502090edf38289b3dea0d': cg_arbitrum_one_mimatic,
    '0xba5ddd1f9d7f570dc94a51479a000e3bce967196': cg_arbitrum_one_aave,
    '0xd22a58f79e9481d1a88e00c343885a588b34b68b': cg_arbitrum_one_stasis_eurs,
    '0x641441c631e2f909700d2f41fd87f0aa6a6b4edb': cg_arbitrum_one_token_dforce_usd,
    '0xae6aab43c4f3e0cea4ab83752c278f8debaba689': cg_arbitrum_one_dforce_token,
    '0x17fc002b466eec40dae837fc4be5c67993ddbd6f': cg_arbitrum_one_frax,
    '0xe9e7cea3dedca5984780bafc599bd69add087d56': cg_bsc_binance_usd,
    '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d': cg_bsc_usd_coin,
    '0x55d398326f99059ff775485246999027b3197955': cg_bsc_tether,
    '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3': cg_bsc_dai,
    '0x4a9a2b2b04549c3927dd2c9668a5ef3fca473623': cg_bsc_dforce_token,
    '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c': cg_bsc_binance_bitcoin,
    '0x2170ed0880ac9a755fd29b2688956bd959f933f8': cg_bsc_weth,
    '0xbf5140a22578168fd562dccf235e5d43a02ce9b1': cg_bsc_uniswap,
    '0x0eb3a705fc54725037cc9e008bdede697f62f335': cg_bsc_cosmos,
    '0x7083609fce4d1d8dc0c979aab8c869ea2c873402': cg_bsc_binance_peg_polkadot,
    '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153': cg_bsc_binance_peg_filecoin,
    '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47': cg_bsc_binance_peg_cardano,
    '0xb5102cee1528ce2c760893034a4603663495fd72': cg_bsc_token_dforce_usd,
    '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd': cg_bsc_chainlink,
    '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe': cg_bsc_binance_peg_xrp,
    '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf': cg_bsc_binance_peg_bitcoin_cash,
    '0x4338665cbb7b2485a8855a139b75d5e34ab0db94': cg_bsc_binance_peg_litecoin,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82': cg_bsc_pancakeswap_token,
    '0x0316eb71485b0ab14103307bf65a021042c6d380': cg_mainnet_huobi_btc,
    '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0': cg_mainnet_dforce_token,
    '0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8': cg_mainnet_token_dforce_usd,
    '0x0994206dfe8de6ec6920ff4d779b0d950605fb53': cg_optimism_curve_dao_token,
    '0xbfd291da8a403daaf7e5e9dc1ec0aceacd4848b9': cg_optimism_token_dforce_usd,
    '0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3': cg_optimism_dforce_token,
    '0x08c15fa26e519a78a666d19ce5c646d55047e0a3': cg_polygon_dforce_token,
    '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': cg_bsc_wbnb,
    '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6': cg_bsc_binance_peg_eos,
    '0xa1faa113cbe53436df28ff0aee54275c13b40975': cg_bsc_alpha_finance,
    '0x4b0f1812e5df2a09796481ff14017e6005508003': cg_bsc_trust_wallet_token,
    '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63': cg_bsc_venus,
    '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7': cg_bsc_vai,
    '0xa184088a740c695e156f91f5cc086a06bb78b827': cg_bsc_auto,
    '0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c': cg_bsc_renbtc,
    '0x250632378e573c6be1ac2f97fcdf00515d0aa91b': cg_bsc_binance_eth,
    '0x9678e42cebeb63f23197d726b29b1cb20d0064e5': cg_bsc_binance_peg_iotex,
    '0x47bead2563dcbf3bf2c9407fea4dc236faba485a': cg_bsc_swipe,
    '0x947950bcc74888a40ffa2593c5798f11fc9124c4': cg_bsc_sushi,
    '0x831753dd7087cac61ab5644b308642cc1c33dc13': cg_polygon_quick,
    '0x50b728d8d964fd00c2d0aad81718b71311fef68a': cg_polygon_havven,
    '0xb33eaad8d922b1083446dc23f610c2567fb5180f': cg_polygon_uniswap,
    '0x8729438eb15e2c8b576fcc6aecda6a148776c0f5': cg_avalanche_benqi,
    '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39': cg_avalanche_binance_usd,
    '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475': cg_fantom_scream,
    '0x6c021ae822bea943b2e66552bde1d2696a53fbb7': cg_fantom_tomb,
    '0x841fad6eae12c286d1fd18d1d525dffa75c7effe': cg_fantom_spookyswap,
    '0xad84341756bf337f5a0164515b1f6f993d194e1f': cg_fantom_fantom_usd,
    '0x3129662808bec728a27ab6a6b9afd3cbaca8a43c': cg_fantom_dola_usd,
    '0xd6070ae98b8069de6b494332d1a1a81b6179d960': cg_fantom_beefy_finance,
    '0x468003b688943977e6130f4f68f23aad939a1040': cg_fantom_spell_token,
    '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8': cg_mainnet_curve_fi_ydai_yusdc_yusdt_ytusd,
    '0x80fb784b7ed66730e8b1dbd9820afd29931aab03': cg_mainnet_ethlend,
    '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2': cg_mainnet_meta,
    '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff': cg_mainnet_serum,
    '0x87edffde3e14c7a66c9b9724747a1c5696b742e6': cg_mainnet_swag_finance,
    '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d': cg_mainnet_celsius_degree_token,
    '0x9be89d2a4cd102d8fecc6bf9da793be995c22541': cg_mainnet_binance_wrapped_btc,
    '0x0391d2021f89dc339f60fff84546ea23e337750f': cg_mainnet_barnbridge,
    '0x9afb950948c2370975fb91a441f36fdc02737cd4': cg_mainnet_huobi_fil,
    '0xdf574c24545e5ffecb9a659c229253d4111d87e1': cg_mainnet_husd,
    '0x584bc13c7d411c00c01a62e8019472de68768430': cg_mainnet_hegic,
    '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713': cg_mainnet_cover_protocol,
    '0xd26114cd6ee289accf82350c8d8487fedb8a0c07': cg_mainnet_omisego,
    '0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28': cg_mainnet_badger_sett_badger,
    '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a': cg_mainnet_basis_cash,
    '0x429881672b9ae42b8eba0e26cd9c73711b891ca5': cg_mainnet_pickle_finance,
    '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26': cg_mainnet_origin_protocol,
    '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7': cg_mainnet_akropolis,
    '0xff20817765cb7f73d4bde2e66e067e58d11095c2': cg_mainnet_amp_token,
    '0xa47c8bf37f92abed4a126bda807a7b7498661acd': cg_mainnet_wrapped_ust,
    '0x4e15361fd6b4bb609fa63c81a2be19d873717870': cg_mainnet_wrapped_fantom,
    '0x3155ba85d5f96b2d030a4966af206230e46849cb': cg_mainnet_thorchain_erc20,
    '0xfca59cd816ab1ead66534d82bc21e7515ce441cf': cg_mainnet_rarible,
    '0xb753428af26e81097e7fd17f40c88aaa3e04902c': cg_mainnet_saffron_finance,
    '0xec67005c4e498ec7f55e092bd1d35cbc47c91892': cg_mainnet_melon,
    '0x1b40183efb4dd766f11bda7a7c3ad8982e998421': cg_mainnet_vesper_finance,
    '0xcc4304a31d09258b0029ea7fe63d032f52e44efe': cg_mainnet_trustswap,
    '0x0000000000095413afc295d19edeb1ad7b71c952': cg_mainnet_tokenlon,
    '0x25f8087ead173b73d6e8b84329989a8eea16cf73': cg_mainnet_yield_guild_games,
    '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b': cg_mainnet_axie_infinity,
    '0xf939e0a03fb07f59a73314e73794be0e57ac1b4e': cg_mainnet_crvusd,
    '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275': cg_bsc_stader_bnbx,
    '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95': cg_bsc_apeswap_finance,
    '0xaf88d065e77c8cc2239327c5edb3a432268e5831': cg_arbitrum_one_usd_coin_2,
    '0x1b19c19393e2d034d8ff31ff34c81252fcbbee92': cg_mainnet_ousg,
    '0xac3e018457b222d93114458476f3e3416abbe38f': cg_mainnet_staked_frax_ether,
    '0x5ca135cb8527d76e932f34b5145575f9d8cbe08e': cg_mainnet_frax_price_index,
    '0x3175df0976dfa876431c2e9ee6bc45b65d3473cc': cg_mainnet_curve_fi_frax_usdc,
    '0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593': cg_mainnet_reth,
    '0xf027e525d491ef6ffcc478555fbb3cfabb3406a6': cg_bsc_stafi_staked_bnb,
    '0x5326e71ff593ecc2cf7acae5fe57582d6e74cff1': cg_arbitrum_one_plvglp,
    '0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18': cg_mainnet_chain_2,
    '0x8d9ba570d6cb60c7e3e0f31343efe75ab8e65fb1': cg_arbitrum_one_governance_ohm,
    '0xd4d42f0b6def4ce0383636770ef773390d85c61a': cg_arbitrum_one_sushi,
    '0xc7283b66eb1eb5fb86327f08e1b5816b0720212b': cg_mainnet_tribe_2,
    '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9': cg_mainnet_alchemix_usd,
    '0x865377367054516e17014ccded1e7d814edc9ce4': cg_mainnet_dola_usd,
    '0xc770eefad204b5180df6a14ee197d99d808ee52d': cg_mainnet_shapeshift_fox_token,
    '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f': cg_mainnet_gitcoin,
    '0xd291e7a03283640fdc51b121ac401383a46cc623': cg_mainnet_rari_governance_token,
    '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9': cg_mainnet_swipe,
    '0x74232704659ef37c08995e386a2e26cc27a8d7b1': cg_mainnet_strike,
    '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd': cg_avalanche_joe,
    '0xf28164a485b0b2c90639e47b0f377b4a438a16b1': cg_polygon_dragons_quick,
    '0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3': cg_bsc_mask_network,
    '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83': cg_mainnet_yfii_finance,
    '0x0341c0c0ec423328621788d4854119b97f44e391': cg_arbitrum_one_silo_finance,
    '0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8': cg_arbitrum_one_rocket_pool_eth,
    '0x9bcef72be871e61ed4fbbc7630889bee758eb81d': cg_optimism_rocket_pool_eth,
    '0x0b2c639c533813f4aa9d7837caf62653d097ff85': cg_optimism_usd_coin_2,
    '0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f': cg_mainnet_gho,
    '0x93b346b6bc2548da6a1e7d98e9a421b42541425b': cg_arbitrum_one_liquity_usd,
    '0x9b06f3c5de42d4623d7a2bd940ec735103c68a76': cg_mainnet_volta_club,
    '0x83f20f44975d03b1b09e64809b757c47f942beea': cg_mainnet_savings_dai,
    '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202': cg_mainnet_kyber_network_crystal,
    '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6': cg_mainnet_stargate_finance,
    '0x14016e85a25aeb13065688cafb43044c2ef86784': cg_bsc_bridged_trueusd,
    '0xce7de646e7208a4ef112cb6ed5038fa6cc6b12e3': cg_bsc_tron_bsc,
    '0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9': cg_bsc_true_usd,
    '0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a': cg_bsc_terrausd_wormhole,
    '0xa2e3356610840701bdf5611a53974510ae27e2e1': cg_bsc_wrapped_beacon_eth,
    '0xfb6115445bff7b52feb98650c87f44907e58f802': cg_bsc_aave,
    '0xcc42724c6683b7e57334c4e856f4c9965ed682bd': cg_bsc_matic_network,
    '0x156ab3346823b651294766e23e6cf87254d68962': cg_bsc_luna_wormhole,
    '0xba2ae424d960c26247dd6c32edc70b295c744c43': cg_bsc_binance_peg_dogecoin,
    '0x55c08ca52497e2f1534b59e2917bf524d4765257': cg_mainnet_uwu_lend,
    '0x3082cc23568ea640225c2467653db90e9250aaa0': cg_arbitrum_one_radiant_capital,
    '0x8dd09822e83313adca54c75696ae80c5429697ff': cg_mainnet_sifu_vision_2,
    '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b': cg_moonbeam_atari,
    '0x922d641a426dcffaef11680e5358f34d97d112e1': cg_moonbeam_tether,
    '0xfa9343c3897324496a05fc75abed6bac29f8a40f': cg_moonbeam_usd_coin,
    '0x765277eebeca2e31912c9946eae1021199b39c61': cg_moonbeam_dai,
    '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73': cg_moonbeam_dai_2,
    '0x5d9ab5522c64e1f6ef5e3627eccc093f56167818': cg_moonriver_binance_usd,
    '0xb44a9b6905af7c801311e8f4e76932ee959c663c': cg_moonriver_anyswap,
    '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d': cg_moonriver_dai_3,
    '0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844': cg_moonriver_dai_4,
    '0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c': cg_moonriver_dai_5,
    '0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8': cg_moonriver_anyswap_2,
    '0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c': cg_moonriver_fsn,
    '0xe176ebe47d621b984a73036b9da5d834411ef734': cg_harmony_binance_usd,
    '0x1a93b23281cc1cde4c4741353f3064709a16197d': cg_moonriver_frax,
    '0xffffffff1fcacbd218edc0eba20fc2308c778080': CIMap.fromRecord({
      moonbeam: cg_moonbeam_xcdot,
      moonriver: cg_moonriver_xcksm,
    }),
    '0x30d2a9f5fdf90ace8c17952cbb4ee48a55d916a7': cg_moonbeam_weth,
    '0x8f552a71efe5eefc207bf75485b356a0b3f01ec9': cg_moonbeam_usd_coin_2,
    '0x322e86852e492a7ee17f28a78c663da38fb33bfb': cg_moonbeam_frax,
    '0xffffffffea09fb06d082fd1275cd48b191cbcd1d': cg_moonbeam_xcusdt,
    '0xc53342fd7575f572b0ff4569e31941a5b821ac76': cg_mainnet_ethereum_volatility_index_token,
    '0x3a707d56d538e85b783e8ce12b346e7fb6511f90':
      cg_mainnet_inverse_ethereum_volatility_index_token,
    '0x269616d549d7e8eaa82dfb17028d0b212d11232a': cg_mainnet_punk_vault_nftx,
    '0x87931e7ad81914e7898d07c68f145fc0a553d8fb': cg_mainnet_wizard_vault_nftx,
    '0x5c6ee304399dbdb9c8ef030ab642b10820db8f56': cg_mainnet_balancer_80_bal_20_weth,
    '0x9c4a4204b79dd291d6b6571c5be8bbcd0622f050': cg_mainnet_tracer_dao,
    '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a': cg_arbitrum_one_arable_protocol,
    '0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af': cg_arbitrum_one_spell_token,
    '0xb766039cc6db368759c1e56b79affe831d0cc507': cg_arbitrum_one_rocket_pool,
    '0xa72159fc390f0e3c6d415e658264c7c4051e9b87': cg_arbitrum_one_tracer_dao,
    '0x8616e8ea83f048ab9a5ec513c9412dd2993bce3f': cg_arbitrum_one_handleusd,
    '0xecf2adaff1de8a512f6e8bfe67a2c836edb25da3': cg_arbitrum_one_rmrk,
    '0xca6d678e74f553f0e59cccc03ae644a3c2c5ee7d': cg_bsc_planet_token,
    '0xb0b84d294e0c75a6abe60171b70edeb2efd14a1b': cg_bsc_synclub_staked_bnb,
    '0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1': cg_gnosis_weth,
    '0x6c76971f98945ae98dd7d4dfca8711ebea946ea6': cg_gnosis_bridged_wrapped_steth_gnosis,
    '0x9c58bacc331c9aa871afd802db6379a98e80cedb': cg_gnosis_gnosis,
    '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d': cg_gnosis_wrapped_xdai,
    '0xaf204776c7245bf4147c2612bf6e5972ee483701': cg_gnosis_savings_xdai,
    '0xcb444e90d8198415266c6a2724b7900fb12fc56e': cg_gnosis_monerium_eur_money,
    '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83': cg_gnosis_usd_coin,
    '0xe3520349f477a5f6eb06107066048508498a291b': cg_aurora_dai,
    '0x07f9f7f963c5cd2bbffd30ccfb964be114332e30': cg_aurora_staked_near,
    '0x8bec47865ade3b172a928df8f990bc7f2a3b9f79': cg_aurora_aurora_near,
    '0xfa94348467f64d5a457f75f8bc40495d33c65abb': cg_aurora_trisolaris,
    '0x09c9d464b58d96837f8d8b6f4d9fe4ad408d3a4f': cg_aurora_aurigami,
    '0xb39eeb9e168ef6c639f5e282fef1f6bc4dcae375': cg_aurora_stader_nearx,
    '0xc42c30ac6cc15fac9bd938618bcaa1a1fae8501d': cg_aurora_wrapped_near,
    '0xb12bfca5a55806aaf64e99521918a4bf0fc40802': cg_aurora_usd_coin,
    '0x4988a896b1227218e4a686fde5eabdcabd91571f': cg_aurora_tether,
    '0xf4eb217ba2454613b15dbdea6e5f22276410e89e': cg_aurora_wrapped_bitcoin,
    '0xd77b108d4f6cefaa0cae9506a934e825becca46e': cg_arbitrum_one_winr_protocol,
    '0xcafcd85d8ca7ad1e1c6f82f651fa15e33aefd07b': cg_arbitrum_one_woo_network,
    '0x004626a008b1acdc4c74ab51644093b155e59a23': cg_arbitrum_one_staked_ageur,
    '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee': cg_mainnet_wrapped_eeth,
    '0xa35b1b31ce002fbf2058d22f30f95d405200a15b': cg_mainnet_stader_ethx,
    '0xb73603c5d87fa094b7314c74ace2e64d165016fb': cg_manta_bridged_usd_coin_manta_pacific,
    '0x0dc808adce2099a9f62aa87d9670745aba741746': cg_manta_bridged_wrapped_ether_manta_pacific,
    '0x305e88d809c9dc03179554bfbf85ac05ce8f18d6': cg_manta_bridged_wrapped_bitcoin_manta_pacific,
    '0xf417f5a458ec102b90352f697d6e2ac3a3d2851f': cg_manta_bridged_tether_manta_pacific,
    '0x2fe3ad97a60eb7c79a976fc18bb5ffd07dd94ba5': cg_manta_bridged_wrapped_steth_manta_pacific,
    '0xec901da9c68e90798bbbb74c11406a32a70652c3': cg_manta_stakestone_ether,
    '0x6c84a8f1c29108f47a79964b5fe888d4f4d0de40': cg_arbitrum_one_tbtc,
    '0x894134a25a5fac1c2c26f1d8fbf05111a3cb9487': cg_arbitrum_one_grai,
    '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4': cg_zksync_era_zksync_bridged_usdc_zksync,
    '0x493257fd37edb34451f62edf8d2a0c418852ba4c': cg_zksync_era_bridged_usdt,
    '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91': cg_zksync_era_weth,
    '0x503234f203fc7eb888eec8513210612a43cf6115': cg_zksync_era_liquity_usd,
    '0xbbeb516fb02a01611cbbe0453fe3c580d7281011': cg_zksync_era_wrapped_bitcoin,
    '0x90059c32eeeb1a2aa1351a58860d98855f3655ad': cg_zksync_era_onez,
    '0x240f765af2273b0cab6caff2880d6d8f8b285fa4': cg_zksync_era_ezkalibur,
    '0x99bbe51be7cce6c8b84883148fd3d12ace5787f2': cg_zksync_era_velocore,
    '0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42': cg_zksync_era_mute,
    '0x4b9eb6c0b6ea15176bbf62841c6b2a8a398cb656': cg_zksync_era_dai,

    '0x95cef13441be50d20ca4558cc0a27b601ac544e5': self_manta_native_coin,
    '0x1985365e9f78359a9b6ad760e32412f4a445e862': self_mainnet_rep,
    '0xbb2b8038a1640196fbe3e38816f3e67cba72d940': self_mainnet_uni_v2_1,
    '0x231b7589426ffe1b75405526fc32ac09d44364c4': self_mainnet_uni_v2_2,
    '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc': self_mainnet_uni_v2_3,
    '0xd3d2e2692501a5c9ca623199d38826e513033a17': self_mainnet_uni_v2_4,
    '0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974': self_mainnet_uni_v2_5,
    '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852': self_mainnet_uni_v2_6,
    '0xb20bd5d04be54f870d5c0d3ca85d82b34b836405': self_mainnet_uni_v2_7,
    '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11': self_mainnet_uni_v2_8,
    '0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f': self_mainnet_uni_v2_9,
    '0xae461ca67b15dc8dc81ce7615e0320da1a9ab8d5': self_mainnet_uni_v2_10,
    '0x8b9734bbaa628bfc0c9f323ba08ed184e5b88da2': self_mainnet_rwa009,
    '0xb9737098b50d7c536b6416daeb32879444f59fca': self_mainnet_rwa008,
    '0x078fb926b041a816facced3614cf1e4bc3c723bd': self_mainnet_rwa007,
    '0x6db236515e90fc831d146f5829407746eddc5296': self_mainnet_rwa005,
    '0x873f2101047a62f84456e3b2b13df2287925d3f9': self_mainnet_rwa004,
    '0x07f0a80ad7aeb7bfb7f139ea71b3c8f7e17156b9': self_mainnet_rwa003,
    '0xaaa760c2027817169d7c8db0dc61a2fb4c19ac23': self_mainnet_rwa002,
    '0x10b2aa5d77aa6484886d8e244f0686ab319a270d': self_mainnet_rwa001,
    '0x06325440d014e39736583c165c2963ba99faf14e': self_mainnet_ste_crv,
    '0xd5147bc8e386d91cc5dbe72099dac6c9b99276f5': self_mainnet_ren_fil,
    '0xfafdf0c4c1cb09d430bf88c75d88bb46dae09967': self_mainnet_ib_aud,
    '0x5555f75e3d5278082200fb451d1b6ba946d8e13b': self_mainnet_ib_jpy,
    '0x95dfdc8161832e4ff7816ac4b6367ce201538253': self_mainnet_ib_krw,
    '0x1337def18c680af1f9f45cbcab6309562975b1dd': self_mainnet_ar_nxm,
    '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831': self_mainnet_dusd,
    '0x9ca85572e6a3ebf24dedd195623f188735a5179f': self_mainnet_y3crv,
    '0x260bbf5698121eb85e7a74f2e45e16ce762ebe11': self_avalanche_axellar_wrapped_ust,
    '0xaf319e5789945197e365e7f7fbfc56b130523b33': self_fantom_frax,
    '0x924828a9fb17d47d0eb64b57271d10706699ff11': self_fantom_sfi,
    '0xc2125882318d04d266720b598d620f28222f3abd': self_arbitrum_one_eux,
    '0x367c17d19fcd0f7746764455497d63c8e8b2bba3': self_bsc_eux,
    '0x20ecc92f0a33e16e8cf0417dfc3f586cf597f3a9': self_bsc_xbtc,
    '0xb986f3a2d91d3704dc974a24fb735dcc5e3c1e70': self_mainnet_eux,
    '0x527ec46ac094b399265d1d71eff7b31700aa655d': self_mainnet_xbtc,
    '0x448bbbdb706cd0a6ab74fa3d1157e7a33dd3a4a8': self_polygon_eux,
    '0x3e121107f6f22da4911079845a470757af4e1a1b': self_polygon_fxs,
    '0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888': self_bsc_cream,
    '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18': self_bsc_band,
    '0x16939ef78684453bfdfb47825f8a5f714f12623a': self_bsc_xtz,
    '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e': self_bsc_yfi,
    '0x101d82428437127bf1608f699cd651e6abf9766e': self_bsc_bat,
    '0x695fd30af473f2960e81dc9ba7cb67679d35edb7': self_bsc_renzec,
    '0xe1146b9ac456fcbb60644c36fd3f868a9072fc6e': self_fantom_fbtc,
    '0x658b0c7613e890ee50b8c4bc6a3f41ef411208ad': self_fantom_feth,
    '0xf43211935c781d5ca1a41d2041f397b8a7366c7a': self_mainnet_frxethcrv,
    '0x1e5f6d5355ae5f1c5c687d3041c55f0aeec57eab': self_bsc_r_atom,
    '0x7829a9810bb84b0e6827f21c81396125d76a2eab': self_bsc_t_fil,
    '0x1dab2a526c8ac1ddea86838a7b968626988d33de': self_bsc_r_dot,
    '0xa7a0a9fda65cd786b3dc718616cee25afb110544': self_bsc_r_eth,
    '0x8f1f15dcf4c70873faf1707973f6029dec4164b3': self_bsc_t_xtz,
    '0xa422885120a6e6bea36949737165d681759bb5bb': self_bsc_t_atom,
    '0x60e4d786628fea6478f785a6d7e704777c86a7c6': self_mainnet_mayc,
    '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d': self_mainnet_bayc,
    '0xb7f7f6c52f2e2fdb1963eab30438024864c313f6': self_mainnet_wpunks,
    '0x1e95a37be8a17328fbf4b25b9ce3ce81e271beb3': self_arbitrum_one_gmd_eth,
    '0x3db4b7da67dd5af61cb9b3c70501b1bdb24b2c22': self_arbitrum_one_gmd_usdc,
    '0x34101fe647ba02238256b5c5a58aeaa2e532a049': self_arbitrum_one_gmd_usdt,
    '0x147ff11d9b9ae284c271b2faae7068f4ca9bb619': self_arbitrum_one_gmd_btc,
    '0x57319d41f71e81f3c65f2a47ca4e001ebafd4f33': self_avalanche_x_joe,
    '0x355c665e101b9da58704a8fddb5feef210ef20c0': self_mainnet_d_force_goldx,
    '0xa9fE4601811213c340e850ea305481afF02f5b28': self_mainnet_weth_yvault,
    '0x4b5bfd52124784745c1071dcb244c6688d2533d3': self_mainnet_curve_yusd,
    '0xc4E15973E6fF2A35cC804c2CF9D2a1b817a8b40F': self_mainnet_ib_btc,
    '0x986b4AFF588a109c09B50A03f42E4110E29D353F': self_mainnet_curve_yv_seth,
    '0xdcd90c7f6324cfa40d7169ef80b12031770b4325': self_mainnet_curve_yv_steth,
    '0x36F3FD68E7325a35EB768F1AedaAe9EA0689d723': self_mainnet_empty_set_dollar,
    '0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa': self_mainnet_tbtc,
    '0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c': self_mainnet_curve_fi_yearn,
    '0xe1237aA7f535b0CC33Fd973D66cBf830354D16c7': self_mainnet_yearn_wrapped_ether,
    '0x27b7b1ad7288079A66d12350c828D3C00A6F07d7': self_mainnet_yv_curve_iron_bank,
    '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07': self_arbitrum_one_joe_token,
    '0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8': self_arbitrum_one_pendle,
    '0x4DEDf26112B3Ec8eC46e7E31EA5e123490B05B8B': self_mainnet_spark_sai,
    '0xf88506B0F1d30056B9e5580668D5875b9cd30F23': self_mainnet_xlon,
    '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B': self_bsc_nuls,
    '0x26FA3fFFB6EfE8c1E69103aCb4044C26B9A106a9': self_mainnet_sspell,
    '0x04F2694C8fcee23e8Fd0dfEA1d4f5Bb8c352111F': self_mainnet_sohm,
    '0xd17479997F34dd9156Deef8F95A52D81D265be9c': self_bsc_usdd,
    '0x12f31B73D812C6Bb0d735a218c086d44D5fe5f89': self_bsc_ageur,
    '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5': self_bsc_hay,
    '0x12BB890508c125661E03b09EC06E404bc9289040': self_bsc_raca,
    '0xfb5B838b6cfEEdC2873aB27866079AC55363D37E': self_bsc_floki,
    '0x352Cb5E19b12FC216548a2677bD0fce83BaE434B': self_bsc_btt,
    '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99': self_bsc_win,
    '0xf307910A4c7bbc79691fD374889b36d8531B08e3': self_bsc_ankr,
    '0xCa3F508B8e4Dd382eE878A314789373D80A5190A': self_bsc_bifi,
    '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827': self_bsc_ankr_bnb,
    '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F': self_bsc_alpaca,
    '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1': self_bsc_bsw,
    '0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16': self_bsc_stkbnb,
    '0x0AaCfbeC6a24756c20D41914F2caba817C0d8521': self_mainnet_yam,
    '0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68': self_mainnet_inv,
    '0x8888801aF4d980682e47f1A9036e589479e835C5': self_mainnet_mph,
    '0xB8c77482e45F1F44dE1745F52C74426C631bDD52': self_mainnet_bnb,
    '0x23B608675a2B2fB1890d3ABBd85c5775c51691d5': self_mainnet_socks,
    '0xc944E90C64B2c07662A292be6244BDf05Cda44a7': self_mainnet_grt,
    '0xA696a63cc78DfFa1a63E9E50587C197387FF6C7E': self_mainnet_yv_wbtc,
    '0x5f18C75AbDAe578b483E5F43f12a39cF75b973a9': self_mainnet_yv_usdc,
    '0x19D3364A399d251E894aC732651be8B0E4e85001': self_mainnet_yv_dai,
    '0xa258C4606Ca8206D8aA700cE2143D7db854D168c': self_mainnet_yv_weth,
    '0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1': self_mainnet_yv_yfi,
    '0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6': self_mainnet_bed,
    '0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1': self_mainnet_data,
    '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD': self_mainnet_eth2x_fli,
    '0x9d409a0A012CFbA9B15F6D4B36Ac57A46966Ab9a': self_mainnet_yv_boost,
    '0x62B9c7356A2Dc64a1969e19C23e4f579F9810Aa7': self_mainnet_cvx_crv,
    '0xc5bDdf9843308380375a611c18B50Fb9341f502A': self_mainnet_yve_crv_dao,
    '0x96Ea6AF74Af09522fCB4c28C269C26F59a31ced6': self_mainnet_yv_link_crv,
    '0xf2db9a7c0ACd427A680D640F02d90f6186E71725': self_mainnet_yv_curve_link,
    '0x559eBC30b0E58a45Cc9fF573f77EF1e5eb1b3E18': self_mainnet_volt,
    '0xD81b1A8B1AD00Baa2D6609E0BAE28A38713872f7': self_mainnet_pc_usdc,
    '0x798D1bE841a82a273720CE31c822C61a67a601C3': self_mainnet_digg,
    '0xfd05D3C7fe2924020620A8bE4961bBaA747e6305': self_mainnet_bve_cvx,
    '0x86ed939B500E121C0C5f493F399084Db596dAd20': self_mainnet_spc,
    '0x6C16119B20fa52600230F074b349dA3cb861a7e3': self_mainnet_alk,
    '0x1571eD0bed4D987fe2b498DdBaE7DFA19519F651': self_mainnet_ifarm,
    '0x875773784Af8135eA0ef43b5a374AaD105c5D39e': self_mainnet_idle,
    '0x2e9d63788249371f1DFC918a52f8d799F4a38C94': self_mainnet_toke,
    '0x73968b9a57c6E53d41345FD57a6E6ae27d6CDB2F': self_mainnet_sdt,
    '0xaC14864ce5A98aF3248Ffbf549441b04421247D3': self_mainnet_x_sdt,
    '0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da': self_mainnet_bpt,
    '0x30647a72Dc82d7Fbb1123EA74716aB8A317Eac19': self_mainnet_im_usd,
    '0x945Facb997494CC2570096c74b5F66A3507330a1': self_mainnet_m_btc,
    '0x17d8CBB6Bce8cEE970a4027d1198F6700A7a6c24': self_mainnet_im_btc,
    '0xBAac2B4491727D78D2b78815144570b9f2Fe8899': self_mainnet_dog,
    '0x6123B0049F904d730dB3C36a31167D9d4121fA6B': self_mainnet_rbn,
    '0x579CEa1889991f68aCc35Ff5c3dd0621fF29b0C9': self_mainnet_iq,
    '0xDFDb7f72c1F195C5951a234e8DB9806EB0635346': self_mainnet_nfd,
    '0xc9BC48c72154ef3e5425641a3c747242112a46AF': self_mainnet_arai,
    '0x618679dF9EfCd19694BB1daa8D00718Eacfa2883': self_mainnet_xyz,
    '0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E': self_mainnet_ilv,
    '0x1A3496C18d558bd9C6C8f609E1B129f67AB08163': self_mainnet_dep,
    '0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb': self_mainnet_inst,
    '0xba5BDe662c17e2aDFF1075610382B9B691296350': self_mainnet_rare,
    '0x35bD01FC9d6D5D81CA9E055Db88Dc49aa2c699A8': self_mainnet_fwb,
    '0x7E9D8f07A64e363e97A648904a89fb4cd5fB94CD': self_mainnet_ff,
    '0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198': self_mainnet_bank,
    '0x0B498ff89709d3838a063f1dFA463091F9801c2b': self_mainnet_btc2x_fli,
    '0x383518188C0C6d7730D91b2c03a03C837814a899': self_mainnet_ohm,
    '0x4104b135DBC9609Fc1A9490E61369036497660c8': self_mainnet_apw,
    '0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B': self_mainnet_forex,
    '0x116172B2482c5dC3E6f445C16Ac13367aC3FCd35': self_mainnet_fx_eur,
    '0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9': self_mainnet_float,
    '0x24A6A37576377F63f194Caa5F518a60f45b42921': self_mainnet_float_bank,
    '0x674C6Ad92Fd080e4004b2312b45f796a192D27a0': self_mainnet_usdn,
    '0x8A9C67fee641579dEbA04928c4BC45F66e26343A': self_mainnet_jrt,
    '0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b': self_mainnet_jeur,
    '0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25': self_mainnet_slp,
    '0x6bB61215298F296C55b19Ad842D3Df69021DA2ef': self_mainnet_dop,
    '0x72377f31e30a405282b522d588AEbbea202b4f23': self_mainnet_vrn,
    '0x4C2e59D098DF7b6cBaE0848d66DE2f8A4889b9C3': self_mainnet_fodl,
    '0x0adA190c81b814548ddC2F6AdC4a689ce7C1FE73': self_mainnet_yaxis,
    '0x4da27a545c0c5B758a6BA100e3a049001de870f5': self_mainnet_stk_aave,
    '0xB1191F691A355b43542Bea9B8847bc73e7Abb137': self_mainnet_kiro,
    '0xb8647e90c0645152fccf4d9abb6b59eb4aa99052': self_mainnet_keyfi,
    '0x70605a6457b0a8fbf1eee896911895296eab467e': self_mainnet_xichi,
    '0xec4325f0518584f0774b483c215f65474eabd27f': self_mainnet_onebtc,
    '0x903bef1736cddf2a537176cf3c64579c3867a881': self_mainnet_ichi,
    '0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8': self_mainnet_jup,
    '0xf4dc48d260c93ad6a96c5ce563e70ca578987c74': self_mainnet_babl,
    '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206': self_mainnet_nexo,
    '0xd632f22692fac7611d2aa1c0d552930d43caed3b': self_mainnet_frax3crv_f,
    '0x900db999074d9277c5da2a43f252d74366230da0': self_mainnet_giv,
    '0x375abb85c329753b1ba849a601438ae77eec9893': self_mainnet_pdt,
    '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3': self_mainnet_rad,
    '0x0fe20e0fa9c78278702b05c333cc000034bb69e2': self_mainnet_ethmaxy,
    '0xf59257e961883636290411c11ec5ae622d19455e': self_mainnet_floor,
    '0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7': self_mainnet_temple,
    '0xae12c5930881c53715b369cec7606b70d8eb229f': self_mainnet_c98,
    '0x5c49b268c9841aff1cc3b0a418ff5c3442ee3f3b': self_avalanche_mai,
    '0xb4efd85c19999d84251304bda99e90b92300bd93': self_mainnet_rpl,
    '0x1dc78acda13a8bc4408b207c9e48cdbc096d95e0': self_moonbeam_wbtc,
    '0x931715fee2d06333043d11f658c8ce934ac61d0c': self_moonbeam_usdc,
    '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359': self_polygon_usdc,
    '0x3d147cd9ac957b2a5f968de9d1c6b9d0872286a0': self_mainnet_fx_php,
    '0x4a717522566c7a09fd2774ccedc5a8c43c5f9fd2': self_arbitrum_one_fei,
    '0x7e141940932e3d13bfa54b224cb4a16510519308': self_mainnet_fx_aud,
    '0xb5de3f06af62d8428a8bf7b4400ea42ad2e0bc53': self_arbitrum_one_brc,
    '0x8d2cb35893c01fa8b564c84bd540c5109d9d278e': self_mainnet_x_eth,
    '0x463e3d1e01d048fdf872710f7f3745b5cdf50d0e': self_bsc_x_eth,
    '0xc5f0f7b66764f6ec8c8dff7ba683102295e16409': self_bsc_fdusd,
    '0x4eac4c4e9050464067d673102f8e24b2fcceb350': self_polygon_ib_btc,
    '0xab3f0245b83feb11d15aaffefd7ad465a59817ed': self_moonbeam_weth,
    '0xe57ebd2d67b462e9926e04a8e33f01cd0d64346d': self_moonbeam_wbtc_2,
    '0x692c57641fc054c2ad6551ccc6566eba599de1ba': self_moonbeam_busd,
    '0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd': self_mainnet_creth2,
    '0xffffffff7d2b0b761af01ca8e25242976ac0ad7d': self_moonbeam_xc_usdc,
    '0xba4cfe5741b357fa371b506e5db0774abfecf8fc': self_mainnet_v_vsp,
    '0x50379f632ca68d36e50cfbc8f78fe16bd1499d1e': self_mainnet_g_uni,
    '0xabddafb225e10b90d798bb8a886238fb835e2053': self_mainnet_g_uni_2,
    '0x81d66d255d47662b6b16f3c5bbfbb15283b05bc2': self_mainnet_ib_zar,
    '0xa05478b34fad86cc86f07eb6698c69ea60b266e3': self_bsc_gold_x,
    '0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3': self_fantom_dei,
    '0x5183e1b1091804bc2602586919e6880ac1cf2896': self_aurora_usn,
    '0x03352d267951e96c6f7235037c5dfd2ab1466232': self_mainnet_one_fox,
    '0x56feaccb7f750b997b36a68625c7c596f0b41a58': self_mainnet_uni_v2,
    '0x470e8de2ebaef52014a47cb5e6af86884947f08c': self_mainnet_uni_v2_11,
    '0x961dd84059505d59f82ce4fb87d3c09bec65301d': self_mainnet_txjp,
    '0xdc98c5543f3004debfaad8966ec403093d0aa4a8': self_mannet_pebble,
    '0xdc49108ce5c57bc3408c3a5e95f3d864ec386ed3': self_mainnet_foxy,
    '0xc27bfe32e0a934a12681c1b35acf0dba0e7460ba': self_mainnet_fste_crv,
    '0x33ed34dd7c40ef807356316b484d595ddda832ab': self_mainnet_fcrv_tricrypto,
    '0x053c80ea73dc6941f518a68e2fc52ac45bde7c9c': self_mainnet_f_usdt,
    '0xab7fa2b2985bccfc13c6d86b1d5a17486ab1e04c': self_mainnet_f_dai,
    '0xfe09e53a81fe2808bc493ea64319109b5baa573e': self_mainnet_f_weth,
    '0x1fd154b4d0e3753b714b511a53fe1fb72dc7ae1c': self_mainnet_swd,
    '0x8290d7a64f25e6b5002d98367e8367c1b532b534': self_mainnet_one_uni,
    '0xf0358e8c3cd5fa238a29301d0bea3d63a17bedbe': self_mainnet_f_usdc,
    '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb2': self_mainnet_bb_a_usd,
    '0x58ca23f163391ecde8625786643c279968fae4ec': self_mainnet_wdg,
    '0xc4ad29ba4b3c580e6d59105fff484999997675ff': self_mainnet_crv_3crypto,
    '0xd83ae04c9ed29d6d3e6bf720c71bc7beb424393e': self_mainnet_insure,
    '0x3d1556e84783672f2a3bd187a592520291442539': self_mainnet_g_uni_3,
    '0x51b0bcbeff204b39ce792d1e16767fe6f7631970': self_mainnet_btcv,
    '0xcf84a3dc12319531e3debd48c86e68eaeaff224a': self_mainnet_g_uni_4,
    '0x3d4e7f52efafb9e0c70179b688fc3965a75bcfea': self_mainnet_ot_c_dai_29dec2022,
    '0x7e7e112a68d8d2e221e11047a72ffc1065c38e1a': self_mainnet_b_digg,
    '0x8fcb1783bf4b71a51f702af0c266729c4592204a': self_mainnet_ot_a_usdc_29dec2022,
    '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b': self_bsc_trx,
    '0x677ddbd918637e5f2c79e164d402454de7da8619': self_mainnet_vusd,
    '0x2590f1fd14ef8bb0a46c7a889c4cbc146510f9c3': self_mainnet_i_btcv,
    '0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e': self_mainnet_met,
    '0x6fae4d9935e2fcb11fc79a64e917fb2bf14dafaa': self_manta_tia,
    '0xbdad407f77f44f7da6684b416b1951eca461fb07': self_manta_wusdm,
    '0x6c3ea9036406852006290770bedfcaba0e23a0e8': self_mainnet_pyusd,
    '0xf8fde8a259a3698902c88bdb1e13ff28cd7f6f09': self_mainnet_fxb_20241231,
    '0x76237bcfdbe8e06fb774663add96216961df4ff3': self_mainnet_fxb_20261231,
    '0x0de54cfdfed8005176f8b7a9d5438b45c4f1df1e': self_mainnet_fxb_20240630,
    '0xfd282f16a64c6d304ac05d1a58da15bed0467c71': self_zksync_era_pepe,
    '0x75af292c1c9a37b3ea2e6041168b4e48875b9ed5': self_zksync_era_cbeth,
    '0x2039bb4116b4efc145ec4f0e2ea75012d6c0f181': self_zksync_era_busd,
    '0x57f5e098cad7a3d1eed53991d4d66c45c9af7812': self_mainnet_wusdm,
    '0x3231cb76718cdef2155fc47b5286d82e6eda273f': self_mainnet_monerium_eur_money,
    '0x35751007a407ca6feffe80b3cb397736d2cf4dbe': self_arbitrum_one_wrapped_eeth,
    '0xbf5495efe5db9ce00f80364c8b423567e58d2110': self_mainnet_ezeth,
    '0xf1c9acdc66974dfb6decb12aa385b9cd01190e38': self_mainnet_oseth,
    '0x4c9edd5852cd905f086c759e8383e09bff1e68b3': self_mainnet_usde,
    '0x9ba021b0a9b958b5e75ce9f6dff97c7ee52cb3e6': self_mainnet_apxeth,
    '0x9d39a5de30e57443bff2a8307a4256c8797a3497': self_mainnet_susde,
    '0x95d7337d43340e2721960dc402d9b9117f0d81a2': self_mainnet_wbc3m,
    '0x0000206329b97db379d5e1bf586bbdb969c63274': self_mainnet_usda,
    '0xabc56186c2df63c338e04c9c9b9814e27983a5a9': self_mainnet_wbib1,
    '0xca2a7068e551d5c4482eb34880b194e4b945712f': self_mainnet_wbib1_2,
    '0xdd629e5241cbc5919847783e6c96b2de4754e438': self_mainnet_mtbill,
    '0x3a287a06c66f9e95a56327185ca2bdf5f031cecd': self_zksync_era_cake,
    '0x703b52f2b28febcb60e1372858af5b18849fe867': self_zksync_era_wsteth,
    '0x0f52a51287f9b3894d73df05164d0ee2533ccbb4': self_manta_matic,
    '0xd9a442856c234a39a81a089c06451ebaa4306a72': self_mainnet_pufeth,
    '0xa1290d69c65a6fe4df752f95823fae25cb99e5a7': self_mainnet_rseth,
    '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca': self_base_USDbC,
    '0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22': self_base_cbETH,
    '0xc1cba3fcea344f92d9239c08c0568f6f2f0ee452': self_base_wstETH,
    '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913': self_base_usdc,
    '0x04c0599ae5a44757c0af6f9ec3b93da8976c150a': self_base_weETH,
    '0x176211869ca2b568f2a7d4ee941e073a821ee1ff': self_linea_usdc,
    '0xa219439258ca9da29e9cc4ce5596924745e12b93': self_linea_usdt,
    '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f': self_linea_weth,
    '0xf3b001d64c656e30a62fbaaca003b1336b4ce12a': self_linea_mai,
    '0x2416092f143378750bb29b79ed961ab195cceea5': self_linea_ez_eth,
    '0x3aab2285ddcddad8edf438c1bab47e1a9d05a9b4': self_linea_wbtc,
    '0xd2671165570f41bbb3b0097893300b6eb6101e6c': self_linea_wrc_eth,
    '0x1bf74c010e6320bab11e2e5a532b5ac15e0b8aa6': self_linea_we_eth,
    '0x15eefe5b297136b8712291b632404b66a8ef4d25': self_linea_uni_eth,
    '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2': self_linea_s_usd_e,
    '0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5': self_linea_dai,
    '0x5d3a1ff2b6bab83b63cd9ad0787074081a52ef34': self_linea_usde,
    '0x93f4d0ab6a8b4271f4a28db399b5e30612d21116': self_linea_stone,
    '0xb5bedd42000b71fdde22d3ee8a79bd49a568fc8f': self_linea_wst_eth,
    '0xecc68d0451e20292406967fe7c04280e5238ac7d': self_linea_frx_eth,
    '0x5a7a183b6b44dc4ec2e3d2ef43f98c5152b1d76d': self_linea_in_eth,
    '0xb20116ee399f15647bb1eef9a74f6ef3b58bc951': self_linea_lyu,
    '0x5ffce65a40f6d3de5332766fff6a28bf491c868c': self_linea_solve_btc_m,
    '0xe4d584ae9b753e549cae66200a6475d2f00705f7': self_linea_m_btc,
  });

export const nativeCoinIcons: Partial<Record<string, StaticImageData>> = {
  mainnet: self_mainnet_eth,
  'arbitrum-one': self_mainnet_eth,
  polygon: cg_mainnet_matic_network,
  optimism: self_mainnet_eth,
  bsc: self_bnb_native_coin,
  avalanche: cg_avalanche_wrapped_avax,
  harmony: self_harmony_native_coin,
  moonbeam: self_moonbeam_native_coin,
  moonriver: self_moonriver_native_coin,
  aurora: self_mainnet_eth,
  manta: self_manta_native_coin,
};
