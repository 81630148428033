import React, { useMemo } from 'react';
import { zeroAddress } from '@akropolis-web/primitives';
import Blockies from 'react-blockies';

import { tokensIcons, nativeCoinIcons } from 'assets/tokens';
import { FontSize, colors, getFontSizeCSS, styled } from 'core/styles';
import { NetworkSlug } from 'domain/types';

import { NextSvgIcon, NextSvgIconProps } from '../NextSvgIcon';
import { NoSSR } from '../NoSSR';

export type TokenIconProps = {
  token: { address: string; network: NetworkSlug };
} & Omit<NextSvgIconProps, 'image'>;

export function TokenIcon(props: TokenIconProps) {
  const {
    token: { address, network },
    fontSize,
    ...rest
  } = props;
  const iconOrMap = useMemo(
    () => (address === zeroAddress ? nativeCoinIcons[network] : tokensIcons.get(address)),
    [address, network],
  );

  const tokenIcon = iconOrMap && 'src' in iconOrMap ? iconOrMap : iconOrMap?.get(network);

  return tokenIcon ? (
    <NextSvgIcon image={tokenIcon} fontSize={fontSize} {...rest} />
  ) : (
    <NoSSR>
      <Root fontSize={fontSize} seed={address} size={8} scale={16} bgColor={colors.silver} />
    </NoSSR>
  );
}

const Root = styled(Blockies, {
  shouldForwardProp: prop => prop !== 'fontSize',
})<{
  fontSize: FontSize | undefined;
}>(({ fontSize }) => ({
  display: 'block',
  borderRadius: '0.5em',
  width: `1em !important`,
  height: '1em !important',
  fontSize: fontSize ? getFontSizeCSS({ fontSize }) : 'inherit',
}));
